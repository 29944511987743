import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { SendOutlined } from "@ant-design/icons"
import CopyToClipboard from "react-copy-to-clipboard";

export default function ShareFileForm({ file }) {
    const [sending, setSending] = useState(false);
    const [link, setLink] = useState("")

    const handleSend = (values) => {
        setSending(true)
        // console.log(values)
    }

    useEffect(() => {
        setLink(window.origin + "/public-file-view/" + file?.unique_ID)
    }, [file])
    // console.log("filee", file)

    return (
        <div>
            <Form
                name="antd_tailwind_form"
                layout="vertical"
                onFinish={handleSend}
            >
                <div className="text-[16px] font-bold mb-5">Share file</div>
                <Form.Item
                    label="Link"
                    name="email"
                    rules={[
                        { required: true, message: 'Please enter your email' },
                        { type: 'email', message: 'Please enter a valid email' },
                    ]}
                >
                    <div className="border rounded-md p-3 w-full">
                        {link}
                    </div>

                </Form.Item>
                <CopyToClipboard text={link}
                    onCopy={() => message.info("Copied")}>
                    <Button type="default">Copy</Button>
                </CopyToClipboard>
            </Form>
        </div>
    );
}
