import { useState } from "react";
import { setDocumentTitle } from "../../../../helpers/seo";
import { Button, Form, Input, message } from "antd";
import { sendAccessRequest } from "../../../../helpers/apis";


export default function EarlyAccessForm() {
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false)


    setDocumentTitle("Request for access - FAST Portal");

    const onFinish = async (values) => {
        // console.log(values);
        setLoading(true);
        try {
            await sendAccessRequest(values);
            setSent(true)
        }
        catch (err) {
            // console.log(err)
            setLoading(false);
            message.error("Failed to send request. Please try again")
        }
        setSent(true);
    }

    return (
        <div className="w-full flex flex-col justify-center text-start h-full p-[48px]">
          

            {sent ? (
                <>
                <div className="text-fast-green font-sans font-[900] text-lg mb-5">
                   Request sent. You have been added to the waitlist <a href="/"><u>learn more</u></a></div>
                </>
            ) : (

                <Form
                    name="reset-form"
                    layout="vertical"
                    onFinish={onFinish}
                >
                      <div className="text-fast-green font-sans font-[900] text-lg mb-5">Request access to FAST Portal</div>
                    <Form.Item
                        label="Full Name"
                        name="name"
                        className="mb-5"
                        rules={[
                            { required: true, message: "Please enter your name!" },
                            { type: "text", message: "Please enter a valid name!" },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        className="mb-5"
                        rules={[
                            { required: true, message: "Please enter your email!" },
                            { type: "email", message: "Please enter a valid email!" },
                        ]}
                    >
                        <Input type="email" />
                    </Form.Item>

                    <Form.Item
                        label="Phone"
                        name="phone_number"
                        className="mb-5"
                        rules={[
                            { required: true, message: "Please enter your phone!" },
                            { type: "phone", message: "Please enter a valid phone!" },
                        ]}
                    >
                        <Input type="phone" />
                    </Form.Item>

                    <Form.Item
                        label="Company"
                        name="company_name"
                        className="mb-5"
                        rules={[
                            { required: true, message: "Please enter your company!" },
                            { type: "text", message: "Please enter a valid company!" },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Website"
                        name="website"
                        className="mb-5"
                        rules={[
                            { required: true, message: "Please enter your website!" },
                            { type: "url", message: "Please enter a valid website!" },
                        ]}
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item>
                        <Button type="primary" loading={loading} className="hover:bg-fast-green mb-3 text-white-600" htmlType="submit" block>
                            Request
                        </Button>
                    </Form.Item>
                    <p className="muted"><a href="/login"><u>Log In</u></a></p>
                </Form>
            )}



        </div>
    );
}