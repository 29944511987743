import { Alert, Button, message } from "antd";
import React, { useState } from "react";
import { retractDeal } from "../../helpers/apis";

export default function InvestorDealModal({ deal }) {
    const [loading, setLoading] = useState(false)

    const handleRetractDeal = async () => {
        try {
            setLoading(true)
            await retractDeal(deal.id);
            message.success("Deal retracted successfully");
        } catch (error) {
            console.error(error);
            message.error("Failed to retract deal. Please try again")
            setLoading(false)
        }
    }


    return (
        <div>
            <div className="my-5">
                <p className="font-bold mb-2">Deal Name :</p>
                <p className="mb-5">
                    {deal.title}
                </p>
                <p className="font-bold mb-2">Round Type :</p>
                <p className="mb-5">
                    {deal.round_details.type}
                </p>
                <p className="font-bold mb-2">Deal Amount :</p>
                <p className="mb-5">
                    ${deal.amount.toLocaleString()}
                </p>

                <div className="my-3 text-[#007DFC]"><a href={`${deal.document}`} target="_blank"><u>View Term Sheet</u></a></div>
                <hr className="mb-5" />
                {
                    deal.status === "rejected" ? (
                        <Alert type="error" message="This deal was rejected." showIcon />
                    ) : (
                        <>

                            {
                                deal.signed_document ? (
                                    <div className="my-3 text-[#007DFC]"><a href={deal.signed_document} target="_blank"><u>View Signed Term sheet</u></a></div>
                                ) : (
                                    <Alert type="warning" description={`${deal.round_details.startup_details.name} has not signed the termsheet`} showIcon />
                                )
                            }

                            {
                                deal.status === "accepted" ? (
                                    <Alert type="success" message="Deal Accepted" />
                                ) : ""
                            }

                            {
                                deal.status !== "retracted" ? (
                                    <Alert type="error" className="mt-[16px]" message={(
                                        <>
                                            <p>Once you retract a deal, you won't be able to perform more actions on the deal.</p>
                                            <Button type="primary" className="mt-5" loading={loading} onClick={handleRetractDeal} ghost>Retract Deal</Button>
                                        </>
                                    )} />
                                ) : ""
                            }


                            {
                                deal.status === "accepted" ? (<div className="flex mt-[56px] justify-end items-center">
                                    <Button type="primary" loading={loading}>Close</Button>
                                </div>) : ""
                            }
                        </>
                    )
                }
            </div>
        </div>
    );
}
