import React, { useContext, useEffect, useState } from "react";
import { Input, DatePicker, Select, Button, message, Modal } from "antd";
import moment from "moment";
import TopStartups from "./components/TopStartups";
import { EyeOutlined } from "@ant-design/icons";
import TableWithPagination from "../../common/TableWithPagination";
import { setDocumentTitle } from "../../../helpers/seo";
import { getInvestorDeals } from "../../../helpers/apis";
import { AccountsContext } from "../../../App";
import InvestorDealModal from "../../common/InvestorDealModal";
import { useNavigate } from "react-router-dom";

export default function Funding() {
    setDocumentTitle("Funding - FAST Portal");
    // State to store filter values
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = React.useState("");
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [selectedRound, setSelectedRound] = React.useState(null);
    const [selectedStartup, setSelectedStartup] = React.useState(null);
    const searchParams = new URLSearchParams(window.location.search);
    const action = searchParams.get('action');
    const dealId = searchParams.get('deal-id');
    const { company } = useContext(AccountsContext);
    const [deals, setDeals] = useState([]);


    const dealColumns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'dealName'
        },
        {
            title: 'Startup',
            dataIndex: 'round_details',
            key: 'startup',
            render: (round_details) => round_details.startup_details.name
        },

        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Date Created',
            dataIndex: 'date_created',
            key: 'deal_date_created',
            render: (date) => moment(date).format("DD/MM/YY HH:mm")
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'dealStatus',
            render: (status) => (<span className={`${status === "open" ? "bg-[#3064E8] text-[#fff]" : "bg-green-400"}  rounded-full p-1 px-3`}>{status}</span>)
        },
        {
            title: '',
            key: 'dealAction',
            render: (record) => <Button type="default" className="min-w-[30px] ml-2 " onClick={() => { navigate(`?action=open-deal&deal-id=${record.id}`) }}><EyeOutlined /></Button>
        },
    ];

    // Extract unique startups and rounds from deals
    const uniqueStartups = [...new Set(deals.map((deal) => deal.round_details.startup_details?.name))];
    const uniqueRounds = [...new Set(deals.map((deal) => deal.round_details?.name))];

    // Handlers for filters
    const handleSearch = (value) => {
        setSearchQuery(value);
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleRoundFilterChange = (value) => {
        setSelectedRound(value);
    };

    const handleStartupFilterChange = (value) => {
        setSelectedStartup(value);
    };

    async function handleGetDeals() {
        try {
            const deals = await getInvestorDeals(company.unique_ID);
            setDeals(deals.reverse())
        } catch (error) {
            message.error("Failed to get deals")
        }

    }

    useEffect(() => {
        handleGetDeals()
    }, [])

    // Filtered deals based on filters
    const filteredDeals = deals.filter((deal) => {
        const nameMatch = deal.title.toLowerCase().includes(searchQuery.toLowerCase());
        const dateMatch = selectedDate ? moment(deal.date_offered).isSame(selectedDate, "day") : true;
        const roundMatch = selectedRound ? deal.round === selectedRound : true;
        const startupMatch = selectedStartup ? deal.investor === selectedStartup : true;
        return nameMatch && dateMatch && roundMatch && startupMatch;
    });


    return (
        <div className="px-[32px] h-full w-full flex-1 overflow-y-auto justify-between flex">
            {
                action === "open-deal" ? (
                    <Modal
                        open={true}
                        onCancel={() => navigate("/investor/funding")}
                        footer={null}
                        width={600}
                    >

                        <InvestorDealModal deal={deals.filter((deal) => deal.id.toString() === dealId)[0]} />
                    </Modal>
                ) : ""
            }
            <div className="main-content flex-1">
                <div className="text-[20px] font-sans font-bold">Funding</div>
                <div className="filters w-full flex justify-between my-[32px]">
                    <Input.Search
                        placeholder="Search deal...."
                        onSearch={handleSearch}
                        style={{ width: 200 }}
                    />
                    <DatePicker
                        placeholder="Select Date"
                        onChange={handleDateChange}
                        style={{ width: 200 }}
                    />
                    <Select
                        placeholder="Filter by round"
                        onChange={handleRoundFilterChange}
                        style={{ width: 200 }}
                        value={selectedRound} // Set the selected value for the filter
                    >
                        {uniqueRounds.map((round) => (
                            <Select.Option key={round} value={round}>
                                {round}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        placeholder="Filter by startup"
                        onChange={handleStartupFilterChange}
                        style={{ width: 200 }}
                        value={selectedStartup} // Set the selected value for the filter
                    >
                        {uniqueStartups.map((startup) => (
                            <Select.Option key={startup} value={startup}>
                                {startup}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
                <div className="my-4 text-[20px] font-bold">
                    Deals
                </div>
                <TableWithPagination data={filteredDeals.length > 0 ? filteredDeals : deals} key="deal-table" columns={dealColumns} />
            </div>
            <TopStartups />
        </div>
    );
}

