import React from "react";

export default function FormsHeader() {
    return (
        <div className="w-full ">
            <div className="text-[24px] container py-4 items-center space-x-2 flex font-[900] font-sans cursor-pointer"  onClick={() => window.location = "/"}>
                <div><img alt="FAST Portal icon" width={24} src="/FAST.svg" /></div>
                <div className="flex"> FAST Portal </div>
                {/* <div className="flex"> FAST Portal <div className="inline-block font-light mt-4 ml-[4px] text-[16px] text-fast-green">beta</div></div> */}
            </div>
        </div>
    );
}
