import { Alert, Button, Checkbox, Skeleton, message } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { axiosHeaders } from "../../helpers/apis";

export default function ManageRoundModal({close }) {
    const [confirmClose, setConfirmClose] = useState(false);
    const [loading, setLoading] = useState(true)
    const [round, setRound] = useState({});
    const [updating, setUpdating] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const openRoundId = searchParams.get('round-id');


    useEffect(() => {
        getRound();
    }, [])


    async function getRound() {
        try {
            const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/rounds/${openRoundId}`, axiosHeaders);
            setRound(request.data.round);
            setLoading(false);
        } catch (error) {
            message.error(error.response.data.detail)
        }
    }


    async function handleCloseRound() {
        setUpdating(true)
        try {
            const request = await axios.put(`${import.meta.env.VITE_API_ENDPOINT_URL}/rounds/${openRoundId}?status=closed`, null, axiosHeaders);
            // console.log(request)
            setRound(request.data.round);
            setLoading(false);
            // getRounds();
            close();
        } catch (error) {
            setUpdating(false)
            message.error(error.response.data.detail)
        }
    }


    return (
        <div className="min-h-[300px]">
            {loading ? <Skeleton /> : (
                <>
                    <div className="text-[20px] font-bold font-sans">Round summary</div>
                    <div className="my-5">
                        <p className="font-bold mb-2">Round Type :</p>
                        <p className="mb-5">
                            {round.type}
                        </p>
                        <p className="font-bold mb-2">Round name :</p>
                        <p className="mb-5">
                            {round.name}
                        </p>
                        <p className="font-bold mb-2">Target amount :</p>
                        <p className="mb-5">
                            {round.target_amount}
                        </p>
                        <p className="font-bold mb-2">Description : </p>
                        <p className="mb-5">
                            {round.comments}
                        </p>
                    </div>
                    {round.status === "open" ? (<>
                        <div className="bg-[#FBF5F0] border border-[#BE5109] p-5 rounded-md">
                            <p>This round is still open. Once closed, investors won’t be able to enter deals with your startup in this round</p>
                            <Checkbox className="mt-4" onChange={() => setConfirmClose(!confirmClose)}>I understand and wish to proceed</Checkbox>
                        </div>
                        <div className="mt-5 flex items-center justify-between">
                            <Button onClick={() => close()} ghost>Cancel</Button>
                            <Button type="primary" loading={updating} disabled={!confirmClose} onClick={handleCloseRound}>Close</Button>
                        </div>
                    </>) : (
                        <Alert message="This round was closed" type="info" showIcon />
                    )}

                </>
            )}
        </div>
    )
}
