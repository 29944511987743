import React from "react";
import ResetPasswordForm from "./components/ResetPasswordForm";
import FormsHeader from "./components/FormsHeader";


export default function ResetPassword() {
    return (
        <>
            <div className="flex mx-auto justify-center mb-[40px] lg:w-3/4 min-w-[320px]"> <FormsHeader /></div>
            <div className="container flex justify-center items-center text-center">

                <div className="lg:w-3/4 flex-wrap flex-wrap-reverse min-w-[320px] rounded-md overflow-hidden min-h-[500px] shadow flex">
                    <div className="flex-1"><ResetPasswordForm /></div>
                    <div className="lg:w-1/2 w-full min-w-[200px] p-3" style={{ backgroundImage: "url('/dunes.jpg')", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                </div>
            </div>
        </>
    );
}
