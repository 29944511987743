import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { logCompanyDocAccess } from "../../helpers/apis";


export default function EnterEmailPopup({docUniqueID}) {
    const [sending, setSending] = useState(false);

    const onFinish = async (values) => {
        setSending(true)
        await logCompanyDocAccess(docUniqueID, values.email)
        localStorage.setItem("cview", true)
        window.location.reload()
    }

    return (
        <div className="min-h-[90vh] w-full justify-center flex items-center">
            <Form name="antd_tailwind_form" layout="vertical" className=" p-5 rounded-md border w-full max-w-[500px]"onFinish={onFinish}>
                <div className="mb-5 font-bold">Please enter your email to view the file</div>
                <Form.Item label="Email" name="email" rules={[
                    { required: true, message: 'A valid Email is required'},
                    { type: "email", message: "Please enter a valid email address!"},
                ]}>
                    <Input placeholder="user@example.com" type="email" />
                </Form.Item>
                <Form.Item className="mt-5">
                    <Button loading={sending} type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}
