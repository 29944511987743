import { Button } from "antd";
import { SendOutlined } from "@ant-design/icons"
import React from "react";
import { useNavigate } from "react-router-dom";

export default function RoundAlert({ round }) {
    const navigate = useNavigate();


    return (
        <div className="alert p-5 mt-3 w-full border rounded-md bg-[#e5f2ff]">
            <p className="mb-4"><b>Company name are in a round !</b></p>
            <div className="round-details">
                <p><b>Name: </b>{round.name}</p>
                <p><b>Target Amount: </b>${round.target_amount.toLocaleString()}</p>
                <p><b>Type: </b> {round.type}</p>
                <p><b>Comments: </b>{round.comments}</p>
                <Button className="mt-4 flex items-center" type="primary" onClick={() => navigate("?action=propose-deal&round-id=" + round.id)}><div className="mr-4">Propose a deal</div> <SendOutlined /></Button>
            </div>
        </div>
    )
}
