import { Button, Form, Input, Upload, message } from "antd";
import React, { useContext, useState } from "react";
import { UploadOutlined } from "@ant-design/icons"
import { AccountsContext } from "../../App";
import { proposeDeal } from "../../helpers/apis";
import { useNavigate } from "react-router-dom";
import Dragger from "antd/es/upload/Dragger";

export default function DealModal() {
    const { company } = useContext(AccountsContext);
    const [sending, setSending] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const roundID = searchParams.get('round-id');
    const [termObj, setTermObj] = useState();
    const navigate = useNavigate();


    const onFinish = async (values) => {
        setSending(true)
        values.term_sheet = termObj;
        // console.log(termObj)
        values.investor_unique_ID = company.unique_ID;
        // console.log('Form values:', values);
        const formData = new FormData();

        // Append each field to the formData instance
        for (const key in values) {
            formData.append(key, values[key]);
        }
        try {
            await proposeDeal(roundID, formData);
            message.success("Your deal proposal has been sent !");
            navigate("/investor/funding")
        } catch (error) {
            console.error(error);
            message.error("Failed to send proposal.")
            setSending(false)
        }
    };


    const customRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("done");
        }, 1000);
    };

    const handleChange = (info) => {
        if (info.file.status === "done") {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    };


    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
        >
            <div className="text-[20px] font-bold font-sans">Propose a deal</div>
            <div className="my-5">
                <p>
                    Rounds involve raising capital from investors to support the growth, development, and operations of the startup.
                </p>
            </div>
            <Form.Item
                label="Deal title"
                name="title"
                rules={[{ required: true, message: 'Please enter the deal title !' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Amount"
                name="amount"
                rules={[{ required: true, message: 'Please enter the amount !' }]}
            >
                <Input type="number"/>
            </Form.Item>
            <Form.Item
                label="Valuation"
                name="valuation"
                rules={[{ required: true, message: 'Please enter the amount !' }]}
            >
                <Input  type="number" />
            </Form.Item>
            <Form.Item
                label="Term Sheet"
                name="term_sheet"
                rules={[{ required: true, message: 'Please upload a termsheet !' }]}
            >
                <Dragger onChange={({ file }) => setTermObj(file)} beforeUpload={() => false} accept=".pdf">
                    <p className="text-gray-700">Click or drag file to this area to upload</p>
                </Dragger>
            </Form.Item>
            <div className="flex w-full mt-5 justify-end">
                <Form.Item>
                    <Button type="primary" loading={sending} className="min-w-[150px]" htmlType="submit">
                        Send
                    </Button>
                </Form.Item>
            </div>

        </Form>
    );
}
