import React, { useContext, useState } from "react";
import { AccountsContext } from "../../App";
import { EditOutlined } from '@ant-design/icons';
import { Avatar, Form, Spin, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { convertFileToBase64 } from "../../helpers/files";
import axios from "axios";
import { axiosHeaders } from "../../helpers/apis";

export default function ManageAccount() {
    const { user, company } = useContext(AccountsContext);
    const [photoObj, setPhotoObj] = useState();
    const [uploading, setUploading] = useState(false);

    const handleUploadProfilePhoto = async (profile_pic) => {
        setUploading(true);
        try {
            const data = { profile_pic };
            const formData = new FormData();

            // Append each field to the formData instance
            for (const key in data) {
                formData.append(key, data[key]);
            }
            await axios.put(`${import.meta.env.VITE_API_ENDPOINT_URL}/users/profile-pic/${user.id}`, formData, axiosHeaders)
            message.success("Profile picture uploaded !")
            setUploading(false)
        } catch (error) {
            message.error("Could not upload profile picture")
            setUploading(false)
        }
    }

    const handleFileDisplay = (file) => {
        if (file) {
            convertFileToBase64(file, (base64Text) => {
                setPhotoObj(base64Text);
                // Handle the base64 text as needed
            });
        }
    }
    return (
        <div className="w-full p-[24px] h-full flex justify-center">
            <Form
                layout="vertical"
                className="flex-1 w-full flex flex-col mt-5 items-center">
                <Form.Item
                    label=""
                    name="logo_file"
                    valuePropName="logo"
                    className="w-full relative"
                    getValueFromEvent={(e) => e.fileList}
                >
                    <Dragger
                        showUploadList={false}
                        className="w-full" multiple={false} accept=".png,.jpg" beforeUpload={() => false} onChange={({ file }) => {
                            // setPhotoObj(file)
                            handleFileDisplay(file);
                            handleUploadProfilePhoto(file)
                        }}>

                        <div className="text-center">
                            <Avatar size={120} src={<img src={photoObj || user.profile_pic} />} >{user.username.charAt(0)}</Avatar>
                            <div className="mt-2">
                                <EditOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                            </div>
                        </div>
                        <p className="text-gray-700">Click or drag an image to this area to upload profile picture</p>
                    </Dragger>
                    {
                        uploading ? (
                            <div className="w-full top-0 h-full flex justify-center items-center absolute" style={{ backgroundColor: "rgba(255, 255, 255, 0.5)" }}>
                                <Spin />
                            </div>
                        ) : ""
                    }
                </Form.Item>
                <div className="text-[20px] font-bold mt-5">
                    {user.username}
                </div>
                <p>{user.email}</p>
                <p className="opacity-60"><i>{user.companyRole}</i></p>
                <div className="rounded-md w-full mt-5 flex items-center border p-4">
                    <div>
                        <Avatar src={company.logo}>{company.name.charAt(0)}</Avatar>
                    </div>
                    <div className="flex-1 px-5">
                        <p><i>company</i></p>
                        <div className="font-bold text-[16px]">{company.name}</div>
                        <p>{company.description}</p>

                    </div>
                </div>
            </Form>

        </div>
    );
}
