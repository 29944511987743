import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { AccountsContext } from "../../App";
import { Empty, Skeleton, Spin } from "antd";
import { markNotificationsAsRead } from "../../helpers/apis";

export default function Notifications({ open, setOpen }) {
    const [loading, setLoading] = useState(true)
    const { notifications } = useContext(AccountsContext);

    const toggleNotifications = () => {
        setOpen(!open)
    }

    useEffect(() => {

        if (open) {
            const unreadNotifications = notifications.filter(notification => notification.status === "unseen")
            if (unreadNotifications.length > 0) {
                const ids = []
                unreadNotifications.forEach(async (notification, i) => {
                    ids.push(notification.id)
                    setLoading(false);
                })
                markNotificationsAsRead(ids)
            }
            else{
                setLoading(false);
            }
        }

    }, [open])

    return (
        <div className={`${open ? "open" : ""} notifications-container shadow-lg p-[24px]`}>
            {loading ? <div className="w-full h-full"><Skeleton /></div> : null}
            <div className="flex justify-between">
                <div className="font-bold text-[16px]">Notifications</div>
                <div className={`mb-5 rounded-sm cursor-pointer`} onClick={toggleNotifications}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.33333 9.33331L22.6667 22.6666M9.33333 22.6666L22.6667 9.33331" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </div>

            {
                notifications.length > 0 ? notifications.map((notification, i) => (
                    <div key={i} className={`${notification.status === "seen" ? "opacity-60" : ""} notification-card py-4 my-3 border-b`}>
                        <div className="notification-title font-bold font-sans">{notification.content}</div>
                        <p>{moment(notification.created_at).fromNow()}</p>
                    </div>
                )) : <Empty description="You have no notifications" />
            }

        </div>
    );
}
