import React from 'react';
import { Row, Col } from 'antd';
import { FacebookOutlined, TwitterOutlined, InstagramOutlined, LinkedinOutlined } from '@ant-design/icons';

const SocialMediaLinks = ({ facebook, twitter, instagram, linkedin }) => {
    return (
        <Row gutter={16} align="middle">
            {facebook && (
                <Col>
                    <a href={facebook} target="_blank" className='inline-flex items-center' rel="noopener noreferrer">
                        <FacebookOutlined size={20} className='mr-4' /> Facebook
                    </a>
                </Col>
            )}
            {twitter && (
                <Col>
                    <a href={twitter} target="_blank" className='inline-flex items-center' rel="noopener noreferrer">
                        <TwitterOutlined size={20} className='mr-4' /> Twitter
                    </a>
                </Col>
            )}
            {/* {instagram && (
                <Col>
                    <a href={instagram} target="_blank" className='inline-flex items-center' rel="noopener noreferrer">
                        <InstagramOutlined size={20} className='mr-4' /> Instagram
                    </a>
                </Col>
            )} */}
            {linkedin && (
                <Col>
                    <a href={linkedin} target="_blank" className='inline-flex items-center' rel="noopener noreferrer">
                        <LinkedinOutlined size={20} className='mr-4' /> Linked In
                    </a>
                </Col>
            )}
        </Row>
    );
};

export default SocialMediaLinks;