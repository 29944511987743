import { Outlet, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import "./Layout.css";
import { useContext, useEffect, useState } from "react";
import { AccountsContext } from "../../App";
import { CalendarOutlined, UserSwitchOutlined, UsergroupAddOutlined, FundOutlined, HomeOutlined, DashboardOutlined } from "@ant-design/icons"
import DesktopNav from "./headers/DesktopNav";
import Notifications from "./Notifications";

export default function Layout() {
    const { user, company, accountsLoading } = useContext(AccountsContext);
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [notificationsOpen, setNotificationsOpen] = useState(false);

    useEffect(() => {
        company.AccountVerification = true;
        company.AccountVerification && !accountsLoading ? "" : navigate(`/account-pending-approval`);
    }, [user, company, accountsLoading])

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen)
    }

    const closeSidebarOnClickAway = () =>{
        if(sidebarOpen){
            setSidebarOpen(false);
        }
    }

    const startupMenuItems = [
        // {
        //     icon: <DashboardOutlined />,
        //     text: "Dashboard",
        //     route: "/startup/dashboard"
        // },
        {
            icon: <HomeOutlined />,
            text: "My Company",
            route: "/startup/company"
        },
        // {
        //     icon: <FundOutlined />,
        //     text: "Funding",
        //     route: "/startup/funding"
        // },
        // {
        //     icon: <UsergroupAddOutlined />,
        //     text: "Investors",
        //     route: "/disabled"
        // },
        // {
        //     icon: (
        //         <>
        //             <UserSwitchOutlined />
        //             {/* <div className="badge text-sm top-[20px] rounded-full absolute right-[56px] flex justify-center w-[16px] h-[16px] text-[#fff] bg-[#BE5109]">2</div> */}
        //         </>

        //     ),
        //     text: "Partnerships",
        //     route: "/disabled"
        // },
        // {
        //     icon: <CalendarOutlined />,
        //     text: "Events",
        //     target: "_blank",
        //     route: "https://portal.fai.institute"
        // }
    ]

    const investorMenuItems = [
        {
            icon: <DashboardOutlined />,
            text: "Dashboard",
            route: "/investor/dashboard"
        },
        // {
        //     icon: <HomeOutlined />,
        //     text: "My Company",
        //     route: "/investor/company"
        // },
        // {
        //     icon: <FundOutlined />,
        //     text: "Funding",
        //     route: "/investor/funding"
        // },
        {
            icon: (
                <>
                    <UserSwitchOutlined />
                    {/* <div className="badge text-sm top-[20px] rounded-full absolute right-[56px] flex justify-center w-[16px] h-[16px] text-[#fff] bg-[#BE5109]">2</div> */}
                </>

            ),
            text: "My Portfolio",
            route: "/disabled"
        },

        {
            icon: <UsergroupAddOutlined />,
            text: "Startups",
            route: "/investor/startups"
        }
        ,
        // {
        //     icon: <CalendarOutlined />,
        //     text: "Events",
        //     target: "_blank",
        //     route: "https://portal.fai.institute"
        // },

    ]

    const menuItems = company.type === "startup" ? startupMenuItems : (company.type === "investor" ? investorMenuItems : []);
    return (
        <>
            <div className="flex w-[100vw]"  onClick={closeSidebarOnClickAway}>
                <div className={`bg-[#EEF0F1] overflow-y-auto responsive-sidebar flex flex-col justify-between shadow w-[240px] ${sidebarOpen ? "open" : ""} px-[36px] py-[24px] h-[100vh] min-h-[600px]`}>
                    <div className="w-full flex justify-end relative">
                        <div className="mb-5 lg-none rounded-sm sidebar-close-button" onClick={toggleSidebar}>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.33333 9.33331L22.6667 22.6666M9.33333 22.6666L22.6667 9.33331" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                    </div>
                    <Sidebar menuItems={menuItems} />
                </div>
                <div className="flex-1 w-[100vw] max-w-none md:max-w-none lg:max-w-[calc(100%-260px)] bg-red-400 lg:h-[100vh] flex flex-col">
                    <DesktopNav setNotificationsOpen={setNotificationsOpen} toggleSidebar={toggleSidebar} />
                    <Outlet />
                    {
                        notificationsOpen ? <Notifications open={notificationsOpen} setOpen={setNotificationsOpen} /> : null
                    }

                </div>
            </div>
        </>
    );
}