import React from "react";
import { setDocumentTitle } from "../../../helpers/seo";
import "./Dashboard.css"
import MainDashboardContent from "./components/MainDashboardContent";
import CompanyDashboardSidebar from "./components/CompanyDashboardSidebar";

export default function StartupDashboard() {
    setDocumentTitle("Startup dashboard - FAST Portal")
    return (
        <div className="flex overflow-y-auto flex-1 lg:max-w-[calc(100vw-240px)] h-full px-[32px]">
            <MainDashboardContent />
            <CompanyDashboardSidebar />
        </div>
    );
}
