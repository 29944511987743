import React, { useContext, useEffect } from "react";
import FormsHeader from "./components/FormsHeader";
import { AccountsContext } from "../../../App";
import { useNavigate } from "react-router-dom";

export default function PendingApproval() {
    const { company, user } = useContext(AccountsContext);
    const navigate = useNavigate()
    useEffect(() => {
        company.AccountVerification ? navigate(`/${company.type}/dashboard`) : false;
    }, [])
    return (
        <div className="w-full h-[100vh] bg-[#EEF0F1] flex-col">
            <FormsHeader />
            <div className="container h-[700px] flex flex-1 flex-col justify-center items-center">
                <img src="/review.gif" />
                <h1 className="font-bold lg:text-[20px] text-[24px] text-fast-green my-[32px]">Pending Approval</h1>
                <p className="text-center max-w-[800px]">
                    Our team is diligently reviewing the provided information to ensure the authenticity and legitimacy of your account. We will be reaching out to you shortly either to approve your account and grant full access to our services or to conduct a verification process to vet the details provided. Your patience and cooperation are greatly appreciated. If you have any questions or require further assistance, please don't hesitate to <b><a href="mailto:support@flapmax.com">contact our support team</a></b>.
                </p>
            </div>
        </div>
    );
}
