import { useRouteError } from "react-router-dom";
import { setDocumentTitle } from "../../helpers/seo";

export default function ErrorPage() {


    const error = useRouteError();
    setDocumentTitle(error.statusText || error.message)


    return (
        <div id="error-page" className=" flex flex-col justify-center items-center h-full">
            <img src="/error_cone.gif" alt="404" />
            <h1 className="font-bold text-fast-green text-[24px]">Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <p>
                <i>
                    Please try again later or contact support.
                </i>
            </p>
            <p>
                <i>{error.statusText || error.message}</i>
            </p>
        </div>
    );
}