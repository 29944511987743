import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function StartInvestingCTA() {
    const navigate = useNavigate()
    return (
        <div className="open-round-cta flex flex-col items-center">
            <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.9689 13.5312C25.3333 14.1084 21.8557 15.4289 18.7533 17.4103C15.6509 19.3917 12.9903 21.9913 10.9375 25.047C8.88479 28.1026 7.48402 31.5486 6.8227 35.1699C6.16137 38.7911 6.25367 42.5098 7.09385 46.0937C8.52711 52.4356 12.2845 58.0104 17.6251 61.7187C21.1895 64.2039 25.304 65.7866 29.615 66.3309C33.9261 66.8753 38.3048 66.3649 42.3751 64.8437C42.8663 64.6184 43.3059 64.2943 43.6665 63.8917C44.027 63.4891 44.3008 63.0166 44.4709 62.5036C44.6409 61.9906 44.7035 61.4481 44.6547 60.9098C44.606 60.3716 44.447 59.8491 44.1876 59.375L33.0001 40V17.0937C33.0045 16.5837 32.8994 16.0787 32.6918 15.6128C32.4842 15.1469 32.179 14.731 31.7968 14.3933C31.4146 14.0555 30.9644 13.8038 30.4765 13.655C29.9886 13.5063 29.4745 13.4641 28.9689 13.5312Z" fill="#2AB2A6" />
                <path d="M45.6875 42.2812C45.3275 42.2882 44.9753 42.3864 44.6637 42.5668C44.3521 42.7472 44.0915 43.0038 43.9062 43.3125C43.7225 43.6308 43.6257 43.9918 43.6257 44.3594C43.6257 44.7269 43.7225 45.088 43.9062 45.4062L53.5312 62.0625C53.7796 62.5064 54.121 62.8913 54.532 63.1911C54.9429 63.4908 55.4138 63.6982 55.9123 63.7991C56.4108 63.9 56.9253 63.892 57.4205 63.7756C57.9156 63.6593 58.3798 63.4374 58.7812 63.125C61.5832 60.9014 63.9096 58.1372 65.6221 54.9967C67.3346 51.8562 68.3983 48.4035 68.75 44.8438C68.777 44.5196 68.7352 44.1934 68.6275 43.8864C68.5198 43.5795 68.3486 43.2987 68.125 43.0625C67.9044 42.8274 67.6383 42.6396 67.3428 42.5107C67.0473 42.3817 66.7286 42.3143 66.4062 42.3125L45.6875 42.2812Z" fill="#FFEABB" />
                <path d="M40.8125 8.34375C40.6385 8.34375 40.4715 8.41289 40.3485 8.53596C40.2254 8.65903 40.1563 8.82595 40.1563 9V32.9687C40.152 33.2408 40.2025 33.5109 40.3047 33.763C40.4068 34.0151 40.5586 34.2442 40.7509 34.4366C40.9433 34.6289 41.1724 34.7807 41.4245 34.8828C41.6766 34.985 41.9467 35.0355 42.2187 35.0312H63.75C64.1628 35.0363 64.5718 34.9524 64.9493 34.7852C65.3268 34.6181 65.6638 34.3716 65.9375 34.0625C66.2178 33.7576 66.4298 33.3964 66.5591 33.003C66.6885 32.6095 66.7323 32.193 66.6875 31.7812C65.8961 25.4234 62.8491 19.5607 58.1006 15.2595C53.352 10.9583 47.2174 8.50426 40.8125 8.34375Z" fill="#76D087" />
                <path d="M61.9062 42.2812C61.9029 45.9529 61.1438 49.5846 59.676 52.9501C58.2083 56.3157 56.0634 59.3431 53.375 61.8438L53.9375 62.75C54.1378 63.1049 54.4105 63.4137 54.738 63.6564C55.0654 63.8991 55.4402 64.0702 55.838 64.1586C56.2359 64.247 56.6479 64.2508 57.0472 64.1696C57.4466 64.0884 57.8245 63.9242 58.1563 63.6875C61.1052 61.453 63.5622 58.6349 65.3741 55.409C67.186 52.1831 68.3139 48.6185 68.6875 44.9375C68.7145 44.6133 68.6727 44.2871 68.565 43.9802C68.4573 43.6733 68.2861 43.3925 68.0625 43.1563C67.8419 42.9211 67.5758 42.7334 67.2803 42.6044C66.9848 42.4755 66.6661 42.4081 66.3437 42.4062L61.9062 42.2812Z" fill="#F7BF75" />
                <path d="M33 16.0625C33.0119 15.6687 32.9351 15.2773 32.7752 14.9173C32.6153 14.5573 32.3764 14.2379 32.0763 13.9828C31.7761 13.7277 31.4224 13.5434 31.0414 13.4436C30.6603 13.3438 30.2617 13.331 29.875 13.4062C28.5468 13.5654 27.2312 13.816 25.9375 14.1562V31.9687C25.9999 37.2148 27.4327 42.3534 30.0937 46.875L37.7187 60.0625C37.9594 60.4421 38.1154 60.8691 38.176 61.3145C38.2366 61.7599 38.2004 62.2131 38.0699 62.6433C37.9394 63.0734 37.7176 63.4703 37.4197 63.8069C37.1218 64.1435 36.7548 64.4119 36.3437 64.5937C34.1654 65.5096 31.8742 66.1297 29.5312 66.4375C30.6827 66.5725 31.8407 66.6456 33 66.6562C36.7006 66.6596 40.3614 65.8934 43.75 64.4062C44.0905 64.2518 44.3945 64.0272 44.6422 63.7472C44.8899 63.4671 45.0757 63.138 45.1875 62.7812C45.3081 62.4325 45.3511 62.0616 45.3133 61.6945C45.2755 61.3274 45.1579 60.9731 44.9688 60.6562C42.6563 56.5937 38 48.5 35.2188 43.7187C33.7847 41.2511 33.03 38.4477 33.0312 35.5937L33 16.0625Z" fill="#337D72" />
                <path d="M40.8127 8.34375C40.6386 8.34375 40.4717 8.41289 40.3486 8.53596C40.2256 8.65903 40.1564 8.82595 40.1564 9C40.1474 9.08309 40.1474 9.16691 40.1564 9.25C45.8376 10.7647 50.8595 14.113 54.4422 18.775C58.0249 23.437 59.9677 29.1516 59.9689 35.0312H63.7502C64.163 35.0363 64.572 34.9524 64.9495 34.7852C65.327 34.6181 65.664 34.3716 65.9377 34.0625C66.218 33.7576 66.43 33.3964 66.5593 33.003C66.6887 32.6095 66.7324 32.193 66.6877 31.7812C65.8963 25.4234 62.8493 19.5607 58.1007 15.2595C53.3522 10.9583 47.2176 8.50426 40.8127 8.34375Z" fill="#76D087" />
            </svg>

            <div className="font-[900] my-3 text-[20px] text-center font-sans">Start investing now !</div>
            <p className="text-center">
                Explore our list of carefully vetted startups to find your next venture.
            </p>
            <Button type="primary" onClick={() => navigate("/investor/startups")} className="w-full mt-3">Explore startups</Button>
        </div>
    );
}
