import { Navigate } from "react-router-dom";
import { userIsLoggedIn } from "../helpers/auth";
import InvestorDashboard from "../components/pages/investors/InvestorDashboard";
import Layout from "../components/layout/Layout";
import ErrorPage from "../components/splashes/ErrorPage";
import Funding from "../components/pages/investors/Funding";
import Startups from "../components/pages/investors/Startups";
import StartupProfile from "../components/pages/investors/StartupProfile";
import Company from "../components/common/Company";
import Messages from "../components/common/Messages";
import ManageAccount from "../components/common/ManageAccount";

export const investorRoutes = [
    {
        path: "/investor",
        element: await userIsLoggedIn() ? <Layout> <Navigate to="dashboard" /> </Layout> : < Navigate to="/login" />,
        children: [
            {
                path: 'dashboard',
                element: <InvestorDashboard />,
                errorElement: <ErrorPage />
            },
            {
                path: 'funding',
                element: <Funding />,
                errorElement: <ErrorPage />
            },
            {
                path: 'startups',
                element: <Startups />,
                errorElement: <ErrorPage />
            },
            {
                path: 'startups/:id',
                element: <StartupProfile />,
                errorElement: <ErrorPage />
            },
            {
                path: 'company',
                element: <Company />,
                errorElement: <ErrorPage />
            },
            {
                path: 'messages',
                element: <Messages />,
                errorElement: <ErrorPage />
            },
            {
                path: 'dashboard/account',
                element: <ManageAccount />,
                errorElement: <ErrorPage />
            },
        ],
        errorElement: <ErrorPage />
    }
] 