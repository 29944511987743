import { Avatar, Button, Tooltip } from "antd";
import { CalendarOutlined, UserAddOutlined, CommentOutlined, LinkOutlined } from "@ant-design/icons";
import moment from "moment";
import React, { useState } from "react";
import TextArea from "antd/es/input/TextArea";

export default function MeetingModal({ meeting }) {
    const [loading, setLoading] = useState(true);
    const start = moment(meeting.start_time);
    const end = moment(meeting.end_time)

    return (
        <div className="flex flex-col w-full h-full justify-center min-h-[300px]">
            <div className="w-full h-full mt-[32px] rounded-md min-h-[300px] p-5" style={{ backgroundColor: "#f5f5f5" }}>
                <div className="text-fast-green text-[16px] font-bold">
                    {meeting.title}
                </div>
                <p className="mt-2 opacity-70">{start.format("DD/MM/YY HH:mm")} ({moment.duration(start.diff(end)).asMinutes()} minutes)</p>
                <div className="bg-[#fff] shadow rounded-md p-4 w-full mt-5">
                    {/* organizer */}
                    <div className="flex items-center w-full">
                        <div className="rounded-full border w-[32px] h-[32px] flex items-center justify-center p-1">
                            <CalendarOutlined />
                        </div>
                        <div className="ml-3 opacity-70">{meeting.scheduler_details?.company_details?.name}</div>
                    </div>
                    {/* attendeed=s */}
                    <div className="flex mt-5 w-full">
                        <div className="rounded-full border w-[32px] h-[32px] flex items-center justify-center p-1">
                            <UserAddOutlined />
                        </div>
                        <div className="ml-4">
                            <div className="opacity-70">Attendees</div>
                            <Avatar.Group className="mt-2">
                                {
                                    meeting.meeting_participants.map((attendee, index) => {
                                        return (
                                            <Tooltip title={attendee.username} placement="top">
                                                <Avatar style={{ backgroundColor: '#87d068' }} src={attendee.profile_pic} >{attendee.username.charAt(0)}</Avatar>
                                            </Tooltip>
                                        )
                                    })
                                }
                            </Avatar.Group>
                        </div>

                    </div>

                    {/* description */}

                    <div className="flex items-center mt-5 w-full">
                        <div className="rounded-full border w-[32px] h-[32px] flex items-center justify-center p-1">
                            <CommentOutlined />
                        </div>
                        <div className="ml-3 flex-1 opacity-70" style={{ textOverflow: "ellipsis", whiteSpace: "no-wrap" }}>
                            {meeting.content}
                        </div>
                    </div>


                    {/* meeting link */}
                    <div className="flex items-center mt-5 w-full">
                        <div className="rounded-full border w-[32px] h-[32px] flex items-center justify-center p-1">
                            <LinkOutlined />
                        </div>
                        <div className="ml-3 flex-1 opacity-70" style={{ textOverflow: "ellipsis", whiteSpace: "no-wrap" }}>
                            <TextArea rows={4} value={meeting.link} />
                        </div>
                    </div>
                    <Button type="primary" className="mt-5 w-full" onClick={() => window.open(meeting.link)}>Join Meeting</Button>
                </div>
            </div>
        </div>
    );
}
