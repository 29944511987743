import React, { useContext, useEffect, useState } from "react";
import UserCard from "./UserCard";
import AddParticipantsField from "./AddParticipantField";
import { Button, Form } from "antd";
import { getCompanyMembers } from "../../helpers/apis";
import { AccountsContext } from "../../App";

export default function ParticipantsModal({ conversation }) {
    const { company } = useContext(AccountsContext);
    const [members, setMembers] = useState([])

    const handleGetAllMembers = async () => {
        try {
            const targetMembers = await getCompanyMembers(conversation.other_company.unique_ID)
            const companyMembers = await getCompanyMembers(company.unique_ID)
            setMembers([...targetMembers, ...companyMembers])
        } catch (error) {
            // console.log("Error", error)
        }
    }
    useEffect(() => {
        handleGetAllMembers()
    }, [conversation])
    const onFinish = async (values) => {
        // console.log(values)
    }
    const filteredUserOptions = members.filter(user => {
        return !conversation.participants.some(participant => participant.id === user.id);
    });
    return (
        <div>
            <div className="text-[16px] font-bold">Add Participants</div>
            <Form onFinish={onFinish} layout="vertical" className="mt-4">
                <AddParticipantsField Form={Form} userOptions={filteredUserOptions} />
                <Button type="primary">Add participant</Button>
            </Form>
            <div className="text-[16px] font-bold mt-5">Participants</div>
            {conversation.participants?.map((participant) => <UserCard user={participant} />)}
        </div>
    );
}
