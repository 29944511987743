import { Avatar, Badge } from "antd";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AccountsContext } from "../../App";

export default function ChatPreviewCard({ conversation }) {
    const {user} = useContext(AccountsContext);
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const conversationId = searchParams.get('cid');

    const unreadMessages =   conversation.last_message.sender.id !== user.id ? conversation.unread_msgs_count: 0
    return (
        <div className={`flex cursor-pointer mt-3 w-full rounded-md ${conversationId === conversation.unique_ID ? "bg-[#fff]" : ""} p-3 items-center`} onClick={() => {
            navigate(`/${localStorage.getItem("c_role")}/messages?cid=${conversation.unique_ID}`)
        }}>
            <Avatar src={conversation.other_company?.logo}>{conversation.other_company?.name.split(" ")[0].charAt(0)}</Avatar>
            <div className="ml-3 flex-1 relative">
                <div className="font-bold truncate max-w-[200px]">{conversation.title}</div>
                <p className="opacity-70 truncate text-sm  max-w-[200px]">{conversation.last_message?.content}</p>
                {unreadMessages > 0 ? <Badge className="bg-fast-orange p-1 absolute right-0 top-3 rounded-full text-[#fff]">{unreadMessages}</Badge> : null}
            </div>
        </div>
    );
}
