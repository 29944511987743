import { RouterProvider } from 'react-router-dom'
import './App.css'
import { router } from './router'
import { createContext, useEffect, useState } from 'react'
import FullScreenLoader from './components/layout/loaders/FullScreenLoader';
import { getCompanyDetails, getConversations, getNotifications, getUserDetails } from './helpers/apis';

// contexts
export const AccountsContext = createContext();
// simplifies reading envs globally - not used alot. To replace
export const env = import.meta.env;

function App() {
  const [user, setUser] = useState();
  const [company, setCompany] = useState();
  const [accountsLoading, setAccountsLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);


  async function getNumberOfUnreadConversations() {
    const conversations = await getConversations(user?.id);
    const unreadConversations = conversations.filter((message) => message.unread_msgs_count > 0 && message.last_message.sender.id !== user.id);
    // console.log("unreadConversations", unreadConversations)
    setUnreadMessagesCount(unreadConversations.length);
  }


  // Fetch user details and set the user state 
  async function fetchUser() {
    try {
      const user = await getUserDetails();
      setUser(user);
    } catch (err) {
      console.error(err);
    }
  }


  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    getNumberOfUnreadConversations();
  }, [user])


  // Fetch company details and set the company state
  async function fetchCompany() {
    try {
      if (user) {
        const company = await getCompanyDetails(user.companyID);
        setCompany(company);
      }
    } catch (err) {
      console.error(err);
    }
  }


  useEffect(() => {
    fetchCompany();
  }, [user]);

  // Update user, company, and localStorage based on fetched data
  useEffect(() => {
    if (user && company) {
      setUser(user);
      setCompany(company);
      localStorage.setItem('c_role', company.type);
      setAccountsLoading(false);
    }
  }, [user, company]);


  // get notifications

  const handleGetNotifications = async () => {
    try {
      const response = await getNotifications(user?.id);
      const sortedNotificaions = response.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setNotifications(sortedNotificaions);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    handleGetNotifications();
  }, [user]);

  return (
    <div className='w-full'>
      <AccountsContext.Provider value={{ user, setUser, getNumberOfUnreadConversations, unreadMessagesCount, notifications, company, fetchCompany, setCompany, accountsLoading, setAccountsLoading }}>
        {accountsLoading ? <FullScreenLoader /> : <RouterProvider router={router} />}
      </AccountsContext.Provider>
    </div>
  )
}

export default App
