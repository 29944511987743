import { Navigate } from "react-router-dom";
import StartupDashboard from "../components/pages/startups/Dashboard";
import ErrorPage from "../components/splashes/ErrorPage";
import { userIsLoggedIn } from "../helpers/auth";
import Layout from "../components/layout/Layout";
import StartupFunding from "../components/pages/startups/Funding";
import Company from "../components/common/Company";
import Investors from "../components/pages/startups/Investors";
import InvestorProfile from "../components/pages/startups/InvestorProfile";
import Messages from "../components/common/Messages";
import ManageAccount from "../components/common/ManageAccount";

export const startupRoutes = [
    {
        path: "/startup",
        element: await userIsLoggedIn() ? <Layout> <Navigate to="dashboard" /> </Layout> : < Navigate to="/login" />,
        children: [
            {
                path: 'dashboard',
                element: <StartupDashboard />,
                errorElement: <ErrorPage />
            },
            {
                path: 'funding',
                element: <StartupFunding />,
                errorElement: <ErrorPage />
            },
            {
                path: 'company',
                element: <Company />,
                errorElement: <ErrorPage />
            },
            {
                path: 'investors',
                element: <Investors />,
                errorElement: <ErrorPage />
            },
            {
                path: 'messages',
                element: <Messages />,
                errorElement: <ErrorPage />
            },
            {
                path: 'investors/:id',
                element: <InvestorProfile />,
                errorElement: <ErrorPage />
            },
            {
                path: 'dashboard/account',
                element: <ManageAccount />,
                errorElement: <ErrorPage />
            },
        ],
        errorElement: <ErrorPage />
    }
] 