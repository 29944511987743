import React, { useState, useEffect} from "react";
import { Button, Divider, List, Typography } from "antd";
import moment from "moment";
import { getCompanyDocAccessLogs } from "../../helpers/apis";


export default function FileViewsModal({doc}) {
    
    const [docAccesses, setDocAccesses] = useState([]);

    useEffect(() => {
        handleGetCompanyDocAccessLogs()
    }, [])

    const handleGetCompanyDocAccessLogs = async () => {
        const docAccesses = await getCompanyDocAccessLogs(doc.unique_ID);
        setDocAccesses(docAccesses)
    }
    
    
    return (
        <>
            <Divider orientation="center"> <Typography.Text strong>{doc.name} ({doc.visibility}) </Typography.Text></Divider>
            <List header="" footer={<div><Typography.Text italic>Document was uploaded</Typography.Text> <Typography.Text strong> {moment(doc.created_at).fromNow()}</Typography.Text></div>}
            bordered dataSource={docAccesses} 
            renderItem={(docAccess) => (
                <List.Item>
                    <Typography.Text type="success" strong>{moment(docAccess.created_at).fromNow()}</Typography.Text> {docAccess.user_email}
                </List.Item>
            )}
            />
        </>
        
    );
}
