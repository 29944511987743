import { message } from "antd";
import axios from "axios";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export default function FlapIdVerify() {
    const [params, _] = useSearchParams();
    const code = params.get("code");

    async function verifyAccount() {
        try {
            const response = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/auth/SIF-verify?code=${code}`);
            // console.log(response.data)
            localStorage.setItem("fsttkn", response.data.accessToken);
            localStorage.setItem("siftkn", response.data.access_token_SIF);
            localStorage.setItem("uid", response.data.userDetails.id);
            window.location = "/"
        }
        catch (error) {
            message.error(error.message)
            window.location = "/"
        }
    }

    useEffect(() => {
        verifyAccount();
        if (!code) {
            window.location = "/"
        }
    }, [])

    return (
        <div className="w-full h-[100vh] flex flex-col justify-center items-center">
            <img src="/rocket-man.gif" />
            <div className=" max-w-[400px]">
                <h1 className="font-bold lg:text-[32px] text-center text-[20px] text-fast-green my-[36px]">Verifying your Flapmax account ...</h1>
            </div>
        </div>
    );
}
