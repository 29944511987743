import React, { useContext, useEffect, useState } from "react";
import MeetingCard from "../../../common/MeetingCard";
import TableWithPagination from "../../../common/TableWithPagination";
import { Alert, Avatar, Button, Empty, Modal } from "antd";
import { CommentOutlined, CalendarOutlined, EyeOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom";
import MeetingModal from "../../../common/MeetingModal";
import { getInvestorNetworks, getMeetings } from "../../../../helpers/apis";
import { AccountsContext } from "../../../../App";
import moment from "moment";

export default function DashboardMainContent() {
    const navigate = useNavigate();
    const { company, user, notifications } = useContext(AccountsContext)
    const searchParams = new URLSearchParams(window.location.search);
    const action = searchParams.get('action');
    const openMeetingId = searchParams.get('open-meeting-id');
    const [networksData, setNetworks] = useState([])
    const [meetings, setMeetings] = useState([])
    const currentDateTime = moment();


    const handleGetMeetings = async () => {
        const meetings = await getMeetings(user.id);


        const approachingMeetings = meetings.filter(meeting => {
            const endTime = moment(meeting.end_time); // ISO format is directly parsed
            return endTime.isAfter(currentDateTime);
        }).sort((a, b) => {
            const startTimeA = moment(a.start_time);
            const startTimeB = moment(b.start_time);
            return startTimeA.diff(startTimeB);
        });

        setMeetings(approachingMeetings);
    }

    useEffect(() => {
        handleGetMeetings();
    }, []);


    useEffect(() => {
        getNetworks();
    }, []);


    async function getNetworks() {
        const networks = await getInvestorNetworks(company.unique_ID);
        setNetworks(networks)
    }

    const networkColumns = [
        {
            title: ' ',
            dataIndex: 'logo',
            key: 'logo',
            render: (logo) => <Avatar src={logo} alt="Company Logo" height={32} width={32} />,
        },
        {
            title: 'Startup',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <div className="flex flex-col"><p><b>{name}</b></p><span><div className="badge mt-2 rounded-full inline-flex text-[#fff] font-bold text-sm px-2 py-1 bg-[#2AB2A6]">Verified</div></span></div>
            )
        },

        {
            title: 'Amount Invested ($)',
            dataIndex: 'amountInvested',
            key: 'amountInvested',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },

        {
            title: '',
            key: 'actions',
            render: (_, record) => (
                <div className="flex">
                    <Button type="primary" ghost className="min-w-[30px] ml-2 " onClick={() => navigate(`/startup/investors/${record.unique_ID}`)}><EyeOutlined /></Button>
                </div>
            )
        },

    ];

    const greeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) {
            return 'Good morning';
        } else if (hour < 18) {
            return 'Good afternoon';
        } else {
            return 'Good evening';
        }
    }
    return (
        <div className="main-dashboard-content h-full flex-1 overflow-y-auto">
            {
                action && openMeetingId ? (
                    <Modal footer={null} width={600} onCancel={() => navigate(`/investor/dashboard`)} open={true}>
                        <MeetingModal meeting={meetings.filter((meeting) => meeting.id.toString() === openMeetingId)[0]} />
                    </Modal>
                ) : ""
            }
            <div className="font-sans text-[16px] mb-5">{greeting()}, {user.username.split(" ")[0]}</div>
            {
                notifications.length > 0 ? (
                    <Alert message={notifications[0].content} className="mb-5" type="info" showIcon />
                ) : ""
            }
            <div className="font-sans text-[20px] font-bold">Upcoming meetings</div>
            <div className="flex w-full min-h-[320px]  max-w-[1020px] items-center overflow-x-auto">
                {
                    meetings.length > 0 ? meetings.map((meeting, key) => (
                        <MeetingCard key={meeting.id} id={openMeetingId} meeting={meeting} />
                    )) : <div className="w-full flex justify-center"><Empty description="You have no incoming meetings" /></div>
                }
            </div>
            <div className="font-sans text-[20px] mb-5 font-bold">Networks</div>
            <TableWithPagination data={networksData} columns={networkColumns} />
        </div>
    );
}
