import { Empty, Input } from "antd";
import React from "react";
import ChatPreviewCard from "./ChatPreviewCard";

export default function MessagesSidebar({ conversations }) {
    return (
        <div className="bg-[#EEF0F1] p-4 rounded-md mr-5 flex-1 max-w-[300px]">
            <div className="text-[20px] font-bold mb-5">Messages</div>
            <Input.Search />
            <div className="mt-5">
                {conversations.length > 0 ? conversations.map((conversation, i) => (
                    <ChatPreviewCard key={i} conversation={conversation} />
                )) : <Empty description="You have no active conversations" />}
            </div>
        </div>
    );
}
