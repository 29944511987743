import { Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDoc } from "../../helpers/apis";
import EnterEmailPopup from "./EnterEmailPopup";

export default function PublicFileView() {
    const [doc, setDoc] = useState({})
    const { id } = useParams();


    const handleGetDoc = async () => {
        try {
            const doc = await getDoc(id);
            setDoc(doc)
        } catch (error) {
            console.error(error);
            message.error("Document not found")
        }
    }

    useEffect(() => {
        handleGetDoc();
    }, [])


    return (
        <div className="w-full">
            <div className="w-full bg-fast-orange text-[#fff] py-3 px-4">
                <div className="container flex justify-between p-0">
                    <div className="flex items-center">
                        <svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.9524 7.98427C12.412 12.4137 7.98249 19.9697 5.18152 24.7249L0.10083 33.3231L2.70635 35.0167C4.20456 35.9287 5.57242 36.515 5.76787 36.2544C6.02842 36.059 9.15504 30.8479 12.7377 24.7249C16.3203 18.6019 19.5772 13.456 19.9029 13.3907C20.2938 13.2605 23.3553 18.0807 26.7425 24.0083C34.7545 38.0132 33.9077 36.971 36.2527 35.7333C37.2949 35.147 38.5326 34.4305 38.9886 34.1048C39.6399 33.6489 37.6207 29.7405 30.3251 17.2991C25.1141 8.37509 20.5544 0.819014 20.2286 0.493332C19.968 0.232766 17.5579 3.61998 14.9524 7.98427Z" fill="white" />
                        </svg>
                        <div className="text-[24px] font-bold ml-3"> FAST Portal</div>
                    </div>
                    <a href="/login" className=" border px-5 py-3 rounded-md flex items-center border-[#fff] text-[#fff]" ghost>Sign In</a>
                </div>
            </div>
            {
                localStorage.getItem("cview") ? (
                    <iframe src={doc.link} className="w-full min-h-[100vh]"></iframe>
                ) : <EnterEmailPopup docUniqueID = {doc.unique_ID}/>
            }

        </div>
    );
}
