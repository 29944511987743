import { Avatar } from "antd";
import React, { useContext, useEffect, useState } from "react";
import UserCard from "../../../common/UserCard";
import { getCompanyMembers } from "../../../../helpers/apis";
import { AccountsContext } from "../../../../App";

export default function CompanySidebar() {
    const { company } = useContext(AccountsContext);
    const [team, setTeam] = useState([]);


    useEffect(() => {
        async function handleGetTeam() {
            const people = await getCompanyMembers(company.unique_ID);
            setTeam(people);
        }
        handleGetTeam()
    }, [])

    return (
        <div className="max-w-[320px] border rounded-sm flex flex-col items-center sm-none h-full flex-1 overflow-y-auto  company-dashboard-sidebar ml-[24px]">
            <Avatar size={80} src={company.logo ? company.logo : null}>{company.name.charAt(0)}</Avatar>

            <div className="my-4 font-bold text-[20px] my-3">{company.name}</div>

            <p className="text-center">
                {company.description}
            </p>

            <div className="badge rounded-full bg-fast-orange mt-4 font-bold text-sm px-[16px] py-1 text-[#fff]">{company.type}</div>

            <div className="dashboard-team-container w-full border-t pt-[24px] mt-[24px]">
                <div className="text-[16px] font-bold text-center">Team</div>
            </div>

            {
                team.map((user, i) => (
                    <UserCard key={i} user={user} />
                ))
            }

        </div>
    );
}
