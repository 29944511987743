import React, { useEffect, useState } from "react";
import TableWithPagination from "../../common/TableWithPagination";
import { Avatar, Button } from "antd";
import { CommentOutlined, CalendarOutlined, EyeOutlined } from "@ant-design/icons"
import Search from "antd/es/input/Search";
import { setDocumentTitle } from "../../../helpers/seo";
import { useNavigate } from "react-router-dom";
import { getStartups } from "../../../helpers/apis";

export default function Startups() {
    setDocumentTitle("Browse Startups - FAST Portal")
    const navigate = useNavigate();
    const [startups, setStartups] = useState([]);
    const startupColumns = [
        {
            title: ' ',
            dataIndex: 'logo',
            key: 'logo',
            render: (logo) => <Avatar src={logo} alt="Company Logo" height={32} width={32} />,
        },
        {
            title: 'Startup',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <div className="flex flex-col"><p><b>{name}</b></p><span><div className="badge mt-2 rounded-full inline-flex text-[#fff] font-bold text-sm px-2 py-1 bg-[#2AB2A6]">In a round</div></span></div>
            )
        },

        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },

        {
            title: '',
            key: 'actions',
            render: (record) => (
                <div className="flex">
                    <Button type="primary" ghost className="min-w-[30px] ml-2 " onClick={() => navigate(record.unique_ID)}><EyeOutlined /></Button>
                </div>
            )
        },

    ];

    useEffect(() => {
        handleGetStartups();
    }, [])

    async function handleGetStartups() {
        const startups = await getStartups();
        setStartups(startups)
    }

    return (
        <div className="px-[32px]">
            <div className="text-[20px] font-bold mb-5">Startups</div>
            <p>Introducing a visionary force in the ever-evolving realm of technology, this dynamic startup is driven by a passionate team of innovators. With a focus on cutting-edge solutions and disruptive advancements, they are poised to redefine the landscape of the industry</p>
            <div className="mt-[64px]">
                <div className="flex justify-end w-full">
                    <Search placeholder="Search startup" className="mb-4" style={{ width: 200 }} />
                </div>
                <TableWithPagination data={startups} columns={startupColumns} />
            </div>
        </div>
    );
}
