export function getConversationById(conversations, id) {
    // console.log("conversations", conversations)
    return conversations.find(conversation => conversation.unique_ID === id);
}

export function getOtherCompanyDetails(currentUserCompanyId, conversation) {
    const currentUser = conversation.participants?.find(user => user.company_ID === currentUserCompanyId);

    if (!currentUser) {
        return [];
    }

    const otherCompanyDetails = conversation.participants
        .filter(user => user.company_ID !== currentUserCompanyId)
        .map(user => user.company_details);

    return otherCompanyDetails;
}