import React, { useContext, useEffect, useState } from "react";
import MessagesSidebar from "./MessagesSidebar";
import { useNavigate } from "react-router-dom";
import ConversationBox from "./ConversationBox";
import "./Messages.css"
import { getConversations } from "../../helpers/apis";
import { AccountsContext } from "../../App";
import { Empty, Modal } from "antd";
import NewConversationModal from "./NewConversationModal";
import FullScreenLoader from "../layout/loaders/FullScreenLoader";
import moment from "moment";

export default function Messages() {
    const searchParams = new URLSearchParams(window.location.search);
    const conversationId = searchParams.get('cid');
    const action = searchParams.get('action');

    const navigate = useNavigate();
    const { user, company } = useContext(AccountsContext)
    const [conversations, setConversations] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (conversations.length > 0) {
            if (!conversationId && !action) {
                navigate(`?cid=${conversations[0].unique_ID}`)
            }
        }
    }, [conversations]);


    async function handleGetConversations() {
        try {
            const conversations = await getConversations(user.id, company.unique_ID);
            
            const sortedConversations = conversations.sort((a, b) => {
                const startTimeA = moment(a.last_message.updated_at);
                const startTimeB = moment(b.last_message.updated_at);
                return startTimeB.diff(startTimeA);
            })

            // console.log(conversations)
            setLoading(false)
            setConversations(sortedConversations);
        } catch (error) {
            console.error("conversations error", error)
        }
    }
    useEffect(() => {
        handleGetConversations()
    }, [conversationId])


    return loading ? <FullScreenLoader /> : (
        <div className="px-[32px] overflow-y-auto flex-1 flex">
            <MessagesSidebar conversations={conversations} />
            {action === "new-conversation" ? (
                <Modal
                    width={500}
                    open={true}
                    onCancel={() => navigate(`/${localStorage.getItem("c_role")}/messages`)}
                    footer={null}
                >
                    <NewConversationModal />
                </Modal>
            ) : ""}
            <div className="flex-1 rounded-md bg-[#EEF0F1] flex flex-col items-center justify-center">
                {conversations.length > 0 && conversationId ? <ConversationBox getConversations={handleGetConversations} conversations={conversations} id={conversationId} /> : <Empty description="You have no messages, connect visit company profiles to get started." />}
            </div>
        </div>
    );
}
