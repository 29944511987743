import { Avatar, Select } from "antd";
const { Option } = Select;

const AddParticipantsField = ({ Form, userOptions }) => {
    // console.log("userOptions", userOptions)

    return (<>
        <Form.Item label="Select Participants" name="participants" rules={[{ required: true, message: 'Please select a user' }]}>
            <Select
                mode="multiple"
                style={{ width: '100%' }}
                placeholder="select participants"
                initialValues={[userOptions[0]?.id]}
                // onChange={(value) => { console.log(value) }}
                optionLabelProp="label"
            >
                {
                    userOptions.map((user) => (
                        <Option value={user?.id} key={user?.id} label={user?.username}>
                            <div className="flex items-center">
                                <Avatar src={user.profile_pic}>{user?.username?.split(" ")[0].charAt(0)}{user?.username?.split(" ")[1]?.charAt(0)}</Avatar>
                                <div className="ml-4">
                                    <div className="font-bold">{user?.username} ({user?.company_details?.name})</div>
                                    <p className="opacity-70">{user?.company_role}</p>
                                </div>
                            </div>
                        </Option>
                    ))
                }
            </Select>
        </Form.Item>
    </>
    );
};

export default AddParticipantsField;