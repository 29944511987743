import { Button } from 'antd'
import { ClockCircleOutlined } from "@ant-design/icons"

export default function HomePage() {
    return (
        <div className='w-full min-h-[100vh] flex flex-col'>
            <div className="text-[24px] container bg-[#fff] rounded-md items-center space-x-2 flex font-[900] font-sans cursor-pointer"  onClick={() => window.location = "/"}>
                <div><img alt="FAST Portal icon" width={24} src="/FAST.svg" /></div>
                {/* <div className="flex"> FAST Portal <div className="inline-block font-light mt-4 ml-[4px] text-[16px] text-fast-green">beta</div></div> */}
                <div className="flex"> FAST Portal </div>
            </div>
            <div className='min-h-[500px] flex flex-col justify-center' style={{ backgroundColor: "rgba(0,0,0,0.5)", backgroundBlendMode: "darken", backgroundImage: "url('/dunes.jpg')" }}>
                <div className="container h-full text-[#fff] flex flex-col justify-center">
                    <div className="text max-w-[800px]">
                        <p className='opacity-70'><b>Connect, Collaborate, Catalyze - FAST Portal. 🚀🌟</b></p>
                        <h1 className='font-bold text-[24px] lg:text-[36px]'>
                            Discover your next success with FAST Portal: Your connection to startup investment
                        </h1>
                        <p className='mt-5 max-w-[600px]'>Empower your visionary startup with the resources it needs to thrive, or embark on a journey of discovery as an investor seeking groundbreaking opportunities. Welcome to FAST Portal, the ultimate platform that seamlessly brings together startups and investors in pursuit of mutual growth and success.</p>
                        <div className="flex mt-[24px]">
                            <Button onClick={() => window.location = "/login"} type="primary" size="large">Sign In</Button>
                            <Button onClick={() => window.location = "/access-request"} className='ml-5 flex items-center border-[#fff] bg-[#fff]' size="large" icon={<ClockCircleOutlined />}>Request Access</Button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <h2 className='font-bold text-[24px]'>
                    What is FAST Portal?
                </h2>
                <p className='mt-5 max-w-[1000px]'>FAST Portal is more than just a platform; it{"'"}s your gateway to a world of entrepreneurial possibilities. Our mission is to bridge the gap between brilliant startup ideas and the capital they need to thrive. Whether you're an early-stage startup or an experienced investor, FAST Portal offers a streamlined, user-friendly experience designed to facilitate meaningful connections and drive business growth.</p>
            </div>
            <div className="container">
                <h3 className='font-bold mb-4 text-[20px]'>
                    <u>How It Works</u>
                </h3>
                <ol className="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                    <li className="flex md:w-full items-center text-blue-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                        <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                            <span className="mr-2">1</span>
                            Create Your Profile
                        </span>
                    </li>
                    <li className="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                        <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                            <span className="mr-2">2</span>
                            Browse Listings
                        </span>
                    </li>
                    <li className="flex items-center">
                        <span className="mr-2">3</span>
                        Connect
                    </li>
                </ol>

            </div>
            <div className="container flex mt-[64px] flex-wrap min-h-[400px]">

                <div className='flex-1 min-w-[300px] lg:min-w-[500px] lg:px-[16px]'>
                    <h3 className='font-bold mb-4 text-[20px]'>
                        <u>For Investors</u>
                    </h3>
                    <ul>
                        <li className='my-4'><b>Discover Innovation: </b> Explore a curated selection of high-potential startups disrupting industries across the globe. Find opportunities aligned with your investment preferences and expertise.</li>
                        <li className='mb-4'><b>Diversify Your Portfolio: </b> Invest in a range of startups at various stages of growth. Build a portfolio that reflects your strategic investment goals and risk tolerance.</li>
                        <li className='mb-4'><b>Engage with Founders:  </b> Connect directly with founders via our secure messaging system. Collaborate, ask questions, and make informed investment decisions.</li>
                    </ul>
                    {/* <Button onClick={() => window.location = "/login"} className='mt-5' type='primary' size='large' ghost>Get started</ Button> */}
                </div>
                <div className="flex-1 min-w-[300px]" style={{ backgroundImage: "url('/FAST for investors.jpg')", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                </div>
            </div>
            <div className="container flex mt-[32px] flex-wrap min-h-[400px]">

                <div className='flex-1 min-w-[300px] lg:min-w-[500px] lg:px-[16px]'>
                    <h3 className='font-bold mb-4 text-[20px]'>
                        <u>For Startups</u>
                    </h3>
                    <ul>
                        <li className='my-4'><b>Unleash Your Potential: </b> Your startup journey begins here. Create a compelling profile to showcase your innovative concepts, your dedicated team, and your unique value proposition.</li>
                        <li className='mb-4'><b>Access to Capital: </b> Connect with a diverse network of investors actively seeking to fund the next big thing. From angel investors to venture capitalists, the funding you need could be just a click away.</li>
                        <li className='mb-4'><b>Expert Guidance: </b> Receive invaluable advice from mentors who have been through the startup grind. Gain insights, refine your pitch, and navigate your way to success.</li>
                    </ul>
                    {/* <Button onClick={() => window.location = "/login"} className='mt-5' type='primary' size='large' ghost>Get started</ Button> */}
                </div>
                <div className="flex-1 min-w-[300px]" style={{ backgroundImage: "url('/FAST For Startups.jpg')", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>

                </div>
            </div>
            <div className="container">
                <h3 className='font-bold mb-4 text-[20px]'>
                    <u>Join FAST Portal Today</u>
                </h3>
                <p>
                    Embark on a journey of innovation, collaboration, and growth. Whether you're a startup ready to disrupt the status quo or an investor seeking the next unicorn, FAST Portal is your springboard to success.
                    <br />
                    Sign up now and become part of a vibrant community dedicated to transforming ideas into reality. The future of entrepreneurship and investment starts here.
                </p>
            </div>
            <div className='text-center py-4 bg-[#efefef]'><small>&copy; 2023 FAST Portal by Flapmax</small></div>
        </div>
    )
}
