import { Alert, Button, Form, Input, message } from "antd";
import { useState } from "react";
import { registerInvitedUser } from "../../../../helpers/apis";

export default function RegisterInviteForm() {
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');

    const onFinish = async (values) => {
        // console.log(values);
        setLoading(true);
        try {
            const data = new FormData();
            data.append("password", values.password);
            await registerInvitedUser(data,token);
            setSent(true)
        }
        catch (err) {
            // console.log(err)
          message.error(err.response.data.detail)
            setLoading(false);
        }
    }

  return token ?(
    <div className="w-full flex flex-col justify-center text-start h-full p-[48px]">
    {sent ? (
        <>
            Password successfully set. You can now <a href="/login"><u>login using your new password.</u></a>
        </>
    ) : (
        <Form
            name="reset-form"
            layout="vertical"
            onFinish={onFinish}
        >
             <div className="text-fast-green font-sans font-[900] text-lg mb-5">Set a password for your account</div>
    <p className="mb-5">Please enter a password to start using FAST Portal.</p>
            <Form.Item
                label="Password"
                name="password"
                className="mb-5"
                rules={[
                    { required: true, message: "Please enter your password!" },
                    { type: "password", message: "Please enter a valid password!" },
                ]}
            >
                <Input.Password />
            </Form.Item>

            <Form.Item>
                <Button type="primary" loading={loading} className="hover:bg-fast-green mb-3 text-white-600" htmlType="submit" block>
                    Register
                </Button>
            </Form.Item>
            <p className="muted"><a href="/login"><u>Log In</u></a></p>
        </Form>
    ) }
</div>
  ):  <Alert type="error" className="m-4" description="Error: Invalid url" showIcon/>
}
