import React, { useContext } from "react";
import { AccountsContext } from "../../../App";
import { Avatar, Button } from "antd";
import { TagOutlined, EditOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom";

export default function ProfileCard({ close }) {
    const { user } = useContext(AccountsContext)
    const navigate = useNavigate();

    async function handleLogout() {
        localStorage.setItem("fsttkn", undefined)
        localStorage.setItem("uid", undefined)
        window.location = "/"
    }
    return (
        <div className="w-full flex flex-col h-full">
            <div className="flex justify-end">
                <span className="opacity-50 cursor-pointer" onClick={close}>
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 1.75C7.175 1.75 1.75 7.175 1.75 14C1.75 20.825 7.175 26.25 14 26.25C20.825 26.25 26.25 20.825 26.25 14C26.25 7.175 20.825 1.75 14 1.75ZM14 24.5C8.225 24.5 3.5 19.775 3.5 14C3.5 8.225 8.225 3.5 14 3.5C19.775 3.5 24.5 8.225 24.5 14C24.5 19.775 19.775 24.5 14 24.5Z" fill="black" />
                        <path d="M18.725 20.125L14 15.4L9.275 20.125L7.875 18.725L12.6 14L7.875 9.275L9.275 7.875L14 12.6L18.725 7.875L20.125 9.275L15.4 14L20.125 18.725L18.725 20.125Z" fill="black" />
                    </svg>
                </span>
            </div>

            <div className="w-full flex-1 flex flex-col items-center justify-between">
                <Avatar src={user.profile_pic} size={80}>{user.username.split(" ")[0].charAt(0)}{user.username.split(" ")[1].charAt(0)}</Avatar>
                <div className="user-details mt-5 text-center">
                    <div className="text-[16px] font-bold font-sans">{user.username}</div>
                    <p>{user.email}</p>
                    <div className="flex justify-center mt-4 items-center">
                        <TagOutlined />
                        <div className="ml-3"><p className="opacity-70"><i>{user.companyRole}</i></p></div>
                    </div>
                </div>
                <div className="actions w-full mt-[24px]">
                    <Button type="primary" icon={<EditOutlined />} className="w-full" onClick={() => {
                        navigate(`/${localStorage.getItem("c_role")}/dashboard/account`);
                        close();
                    }}>Manage Account</Button>
                    <Button type="default" className="w-full mt-2" onClick={handleLogout}>Log Out</Button>
                </div>
            </div>
        </div>
    );
}
