import React from "react";
import DashboardMainContent from "./components/DashboardMainContent";
import CompanySidebar from "./components/CompanySidebar";

export default function InvestorDashboard() {
    return (
        <div className="flex overflow-y-auto flex-1 h-full px-[32px]">
            <DashboardMainContent />
            <CompanySidebar />
        </div>
    );
}
