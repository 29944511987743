import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { setDocumentTitle } from "../../helpers/seo";
import { LinkedinOutlined, EditOutlined, UploadOutlined, ShareAltOutlined, DeleteOutlined } from "@ant-design/icons"
import { Avatar, Button, Modal, message, Tag, Typography, Flex } from "antd";
import MyCompanyProfileSidebar from "./MyCompanyProfileSidebar";
import TableWithPagination from "./TableWithPagination";
import FileUploadModal from "./FileUploadModal";
import FileViewsModal from "./FileViewsModal";
import EditCompanyModal from "./EditCompanyModal";
import { AccountsContext } from "../../App";
import SocialMediaLinks from "./SocialMediaLinks";
import { deleteCompanyDocument, getCompanyDocs } from "../../helpers/apis";
import FileViewModal from "./FileViewModal";
import ShareFileForm from "./ShareFileForm";


export default function Company() {
    setDocumentTitle("Company - Manage Your company's profile");
    const searchParams = new URLSearchParams(window.location.search);
    const location = useLocation()
    const navigate = useNavigate()
    const { company } = useContext(AccountsContext);
    const [docs, setDocs] = useState([]);
    const [selectedDoc, setSelectedDoc] = useState();
    const action = searchParams.get('action');
    const [deleting,setDeleting] = useState(false)
    
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 767);



    useEffect(() => {

        try { 
            handleGetCompanyDocs()
        } catch (error) {
            if (error.response.status === 401){
                navigate("/login", {state: {from: location}, replace: true})
            }
            window.location.reload();
        } // handle user token expiry
        
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 767);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])


    const handleGetCompanyDocs = async () => {
        const docs = await getCompanyDocs(company.unique_ID);
        setDocs(docs)
    }

    const handleDeleteDoc = async (id) => {
        setDeleting(true)   
        try {
            await deleteCompanyDocument(id);
            message.success("Document deleted successfully");
            setDeleting(false)
            handleGetCompanyDocs();
        
        } catch (error) {
            message.error("Failed to delete document. Please try again")
            setDeleting(false)
        }
    }

    const handleOpenFile = (file) => {
        setSelectedDoc(file);
        navigate("?action=open-doc")
    }

    const handleOpenFileViews = (file) =>{
        setSelectedDoc(file);
        navigate("?action=open-views")        
    }

    const documentsColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            className: 'w-full'
        },
        // {
        //     title: 'Uploader(Name)',
        //     dataIndex: 'uploader',
        //     key: 'uploader',
        //     render: (_, record) => (<Typography.Text>{record.uploader_details.username}</Typography.Text>)
        // },
        {
            title: 'Visibility',
            dataIndex: 'visible',
            key: 'visible',
            responsive: ['md', 'lg'],
            render: (_, record) => (
                <Tag  bordered={false} color={record.visibility == "public" ? "green" : "volcano"}>{record.visibility}</Tag>
            )
        },
        // {
        //     title: 'Views',
        //     dataIndex: 'visible',
        //     key: 'visible',
        //     responsive: ['md', 'lg'],
        //     render: (link, record) => <Button type="default" onClick={() => { handleOpenFileViews(record) }}>Views</Button>
        // },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            responsive: ['md', 'lg'],
            render: (link, record) => <Button type="default" onClick={() => { handleOpenFile(record) }}>Open</Button>
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            className: 'w-full',
            render: (link, record) => (
                <Flex gap="small">
                    {isSmallScreen ? (<Button type="default" className="sm:ml-1" onClick={() => { handleOpenFile(record) }}>Open</Button>):("")}
                    <Button type="primary" className="ml-4 md:ml-2 sm:ml-1" onClick={() => { setSelectedDoc(record);navigate("?action=share-doc&doc-id=" + record.id)}} icon={<ShareAltOutlined />} ghost>Share</Button>
                    <Button type="default" className="ml-4 md:ml-2 sm:ml-1" onClick={()=>handleDeleteDoc(record.unique_ID)} icon={<DeleteOutlined />} loading={deleting} danger>Delete</Button>
                </Flex>

            )
        },
    ]
    return (
        <div className=" w-full flex-1 overflow-y-auto">
            <div className="min-h-[200px] py-5 w-full" style={{ backgroundImage: "url('/dunes.jpg')", backgroundSize: "cover", backgroundAttachment: "fixed" }}>
            </div>
            <div className="w-full lg:py-[24px] lg:px-[40px] md:py-[16px] md:px-[30px] sm:py-[12px] sm:px-[20px] xl:py-[32px] xl:px-[50px]">
                {
                    action === "share-doc" ? (
                        <Modal
                            open={true}
                            onCancel={() => navigate(`/${localStorage.getItem("c_role")}/company`)}
                            footer={null}
                        >
                            <ShareFileForm file={selectedDoc} />
                        </Modal>
                    ) : ""
                }
                {
                    action === "open-views" ? (
                        <Modal
                            open={true}
                            onCancel={() => navigate(`/${localStorage.getItem("c_role")}/company`)}
                            footer={null}
                        >
                            <FileViewsModal onCancel={() => navigate(`/${localStorage.getItem("c_role")}/company`)} doc={selectedDoc} />
                        </Modal>
                    ) : ""
                }
                {
                    action === "open-doc" ? (
                        <Modal
                            open={true}
                            onCancel={() => navigate(`/${localStorage.getItem("c_role")}/company`)}
                            footer={null}

                            width={1300}
                        >
                            <FileViewModal doc={selectedDoc} />
                        </Modal>
                    ) : ""
                }
                {
                    action === "upload-file" ? (
                        <Modal
                            open={true}
                            onCancel={() => navigate(`/${localStorage.getItem("c_role")}/company`)}
                            footer={null}
                        >
                            <FileUploadModal onCancel={() => navigate(`/${localStorage.getItem("c_role")}/company`)} docs={docs} getDocs={handleGetCompanyDocs} />
                        </Modal>
                    ) : ""
                }
                {
                    action === "edit-profile" ? (
                        <Modal
                            width={1000}
                            open={true}
                            onCancel={() => navigate(`/${localStorage.getItem("c_role")}/company`)}
                            footer={null}
                        >
                            <EditCompanyModal close={() => navigate(`/${localStorage.getItem("c_role")}/company`)} />
                        </Modal>
                    ) : ""
                }
                <div className="flex-1 min-w-[300px]">
                    <Avatar size={120} src={<img src={company.logo} alt={company.name.charAt(0)} />} className={`mt-[-90px] ${company.logo === null ? "bg-[#007DFC] text-[24px] text-[#fff]" : " bg-[#fff] text-fast-slate"} shadow`} style={{ border: "solid 8px #fff" }}>{company.name.charAt(0)}</Avatar>
                    <div className="flex items-center">
                        <div className="text-[16px] font-bold my-5">{company.name}</div>
                        <Button type="default" className="ml-2" size="small" icon={<EditOutlined />} onClick={() => navigate("?action=edit-profile")}>Edit profile</Button>
                    </div>
                    <p>
                        {
                            company.description
                        }
                    </p>
                    <div className="w-full flex my-5 opacity-60 items-center">
                        <SocialMediaLinks {...company} />
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="text-[16px] font-bold"> </div>
                        <div className="flex mt-5">
                           <Button ghost type="primary" className="flex-1" size="large" onClick={() => navigate("?action=upload-file")} icon={<UploadOutlined />}>Upload a file</Button>
                        </div>
                    </div>
                    <div className="mt-4">
                        <TableWithPagination columns={documentsColumns} data={docs} />
                    </div>

                </div>
                <MyCompanyProfileSidebar />
            </div>
        </div>
    );
}
