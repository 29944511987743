
export function convertFileToBase64(file, callback) {
    const reader = new FileReader();

    reader.onload = function (event) {
        callback(event.target.result);
    };

    reader.readAsDataURL(file);
}

