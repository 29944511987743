import React, { useEffect, useState } from "react";
import TableWithPagination from "../../common/TableWithPagination";
import { Avatar, Button } from "antd";
import { CommentOutlined, CalendarOutlined, EyeOutlined } from "@ant-design/icons"
import Search from "antd/es/input/Search";
import { setDocumentTitle } from "../../../helpers/seo";
import { useNavigate } from "react-router-dom";
import { getInvestors } from "../../../helpers/apis";

export default function Investors() {
    setDocumentTitle("Browse Investors - FAST Portal")
    const [investorsData, setInvestors] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        handleGetInvestors()
    }, [])


    async function handleGetInvestors() {
        const investors = await getInvestors();
        setInvestors(investors)
    }
    const investorColumns = [
        {
            title: 'Investor',
            dataIndex: 'name',
            key: 'name',
            render: (name, record) => (
                <div className="flex items-center">
                    <Avatar src={record.logo} alt="Company Logo" height={48} width={48} >{name.charAt(0)}</Avatar>
                    <div className="flex ml-5 flex-col"><p><b>{name}</b></p>
                        {record.verified ? (<span>
                            <div className="badge mt-2 rounded-full inline-flex text-[#fff] font-bold text-sm px-2 py-1 bg-[#2AB2A6]">Verified</div>
                        </span>) : (
                            <span>
                                <div className="badge mt-2 rounded-full inline-flex font-bold text-sm px-2 py-1 text-fast-orange bg-[#efefef]">Not verified</div></span>
                        )}

                    </div>
                </div>

            )
        },

        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
        },

        {
            title: '',
            key: 'actions',
            render: (record) => (
                <div className="flex">
                    <Button type="primary" ghost className="min-w-[30px] ml-2 " onClick={() => navigate(record.unique_ID)}><EyeOutlined /></Button>
                </div>
            )
        },

    ];
    return (
        <div className="px-[32px]">
            <div className="text-[20px] font-bold mb-5">Investors</div>
            <p>Introducing a visionary force in the ever-evolving realm of technology, this dynamic startup is driven by a passionate team of innovators. With a focus on cutting-edge solutions and disruptive advancements, they are poised to redefine the landscape of the industry</p>
            <div className="mt-[64px]">
                <div className="flex justify-end w-full">
                    <Search placeholder="Search investor" className="mb-4" style={{ width: 200 }} />
                </div>
                <TableWithPagination data={investorsData} columns={investorColumns} />
            </div>
        </div>
    );
}
