import { Form } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { Input, Button, Avatar, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import Select from 'react-select';
import React, { useContext, useState } from "react";
import countries from 'react-select-country-list';
import { convertFileToBase64 } from "../../helpers/files";
import { updateCompanyProfile } from "../../helpers/apis";
import { AccountsContext } from "../../App";

export default function EditCompanyModal({ close }) {
    const { company, fetchCompany } = useContext(AccountsContext)
    const [logo, setLogo] = useState(company.logo);
    const [logoObj, setLogoObj] = useState({});
    const [name, setName] = useState(company.name)
    const [description, setDescription] = useState(company.description)
    const [linkedin, setLinkedIn] = useState(company.linkedin || undefined)
    const [facebook, setFacebook] = useState(company.facebook || undefined)
    const [twitter, setTwitter] = useState(company.twitter || undefined)
    const [website, setWebsite] = useState(company.website || undefined)
    const [location, setLocation] = useState(company.location)
    const [category, setCategory] = useState(company.category)
    const [employee_size, setEmployeeSize] = useState(company.employee_size)
    const [updating, setUpdating] = useState(false)


    const handleFileDisplay = (file) => {
        if (file) {
            convertFileToBase64(file, (base64Text) => {
                setLogo(base64Text);
                // Handle the base64 text as needed
            });
        }
    }


    const onFinish = async (values) => {
        setUpdating(true)
        try {
            // console.log(values)
            // BUG: Value list returns location as an object
            values.location = values.location ? values.location.label : null;
            values.category = values.category ? values.category.label : null;
            // console.log(values.logo_file)
            values.logo_file = values.logo_file ? logoObj : null;
            values.employee_size = parseInt(employee_size)

            if (values.logo_file === null) {
                delete values["logo_file"]
            }
            const data = { ...values, name, email: company.email, category, description, linkedin, facebook, website, location, twitter }
            // delete invalid keys
            for (const key in data) {
                if (data[key] === null || data[key] === undefined || data[key] === "null" || data[key] === "None") {
                    delete data[key];
                }
            }
            await updateCompanyProfile(data, company.unique_ID)
            setUpdating(false)
            fetchCompany()
            message.success('Profile updated successfully!');
            close()
        } catch (error) {
            console.log(error)
            message.error("Failed to update company");
            setUpdating(false)
        }


    };
    
    const categoryOptions = [
        { value: 'Agriculture', label: 'Agriculture' },
        { value: 'FinTech', label: 'FinTech' },
        { value: 'Health', label: 'Health' },
        { value: 'Education', label: 'Education' },
        { value: 'Sustainability', label: 'Sustainability' },
        { value: 'Industrial', label: 'Industrial' }
    ]

    const countryList = countries().getData();
    const countryOptions = countryList.map((country)=>{
        // {value: country.label, label:country.label};
        // <Select.Option value={country.label}>{country.label}</Select.Option>
        return {value: country.label, label:country.label};
    })

    return (
        <Form name="antd_tailwind_form" layout="vertical" onFinish={onFinish} >
            <Form.Item label="Edit Avatar/Logo" name="logo_file" valuePropName="logo" getValueFromEvent={(e) => e.fileList} >
                <Dragger multiple={false} accept=".png,.jpg" beforeUpload={() => false} onChange={({ file }) => { setLogoObj(file); handleFileDisplay(file) }}>
                    <div className="text-center">
                        <Avatar size={100} src={<img src={logo} alt="logo" />} 
                            className={` ${logo === null ? "bg-[#007DFC] text-[24px] text-[#fff]" : " bg-[#fff] text-fast-slate"} shadow`} 
                            style={{ border: "solid 8px #fff" }}>{company.name.charAt(0)}</Avatar>
                            <div className="mt-2">
                                <EditOutlined style={{ fontSize: '24px', color: '#1890ff' }} />
                            </div>
                    </div>
                    <p className="text-gray-700">Click or drag an image to this area to upload<br /> (<i>Please make sure the logo you upload is of high reesolution</i>)</p>
                </Dragger>
            </Form.Item>
            
            <Form.Item label={"Company Name: " + name} name="name">
                <Input value={name} defaultValue={name} onChange={(e) => setName(e.target.value)} disabled/>
            </Form.Item>

            <Form.Item label="Employee Size" name="employee_size">
                <Input value={employee_size} type="number" defaultValue={employee_size} onChange={(e) => setEmployeeSize(e.target.value)} />
            </Form.Item>
            
            <Form.Item label="Company Bio" name="description">
                <Input.TextArea value={description} defaultValue={description} onChange={(event) => setDescription(event.target.value)} />
            </Form.Item>
            
            <Form.Item label={`Category: ${category}`} name="category" disabled>
                <Input value={category} defaultValue={category} onChange={({ value }) => setCategory(value)} disabled/>
                {/*<Select options={categoryOptions} placeholder="Select a category" onChange={({ value }) => setCategory(value)}/>*/}
               
            </Form.Item>
            
            <Form.Item label="Location" name="location">
                <Input value={location} disabled/>
                <Select options={countryOptions} placeholder="Select a country" onChange={({ value }) => setLocation(value)}/>
            </Form.Item>


            <Form.Item label="Website" name="website">
                <Input value={website} defaultValue={website} onChange={(e) => setWebsite(e.target.value)} />
            </Form.Item>

            <Form.Item label="LinkedIn Profile" name="linkedIn">
                <Input value={linkedin} defaultValue={linkedin} onChange={(e) => setLinkedIn(e.target.value)} />
            </Form.Item>

            <Form.Item label="Facebook Profile" name="facebook">
                <Input value={facebook} defaultValue={facebook} onChange={(e) => setFacebook(e.target.value)} />
            </Form.Item>

            <Form.Item label="X Profile" name="twitter">
                <Input value={twitter} defaultValue={twitter} onChange={(e) => setTwitter(e.target.value)} />
            </Form.Item>

            <Form.Item>
                <Button type="primary" loading={updating} htmlType="submit">
                    Save Changes
                </Button>
            </Form.Item>
        </Form>
    );
}
