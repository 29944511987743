import { Navigate, createBrowserRouter } from "react-router-dom";
import ErrorPage from "./components/splashes/ErrorPage";
import LoginPage from "./components/pages/Auth/LoginPage";
import VerifyAuth from "./components/pages/Auth/VerifyAuth";
import { userIsLoggedIn } from "./helpers/auth";
import PendingApproval from "./components/pages/Auth/PendingApproval";
import { startupRoutes } from "./routes/StartupRoutes";
import { investorRoutes } from "./routes/InvestorRoutes";
import FlapIdVerify from "./components/pages/Auth/FlapIdVerify";
import PublicFileView from "./components/common/PublicFileView";
import ResetPassword from "./components/pages/Auth/ResetPassword";
import SetPassword from "./components/pages/Auth/SetPassword";
import HomePage from "./components/pages/HomePage";
import RequestAccess from "./components/pages/Auth/AccessRequest";
import RegisterInvitedUser from "./components/pages/Auth/RegisterInvitedUser";

// BUG: Could cause issues with routing when the saved role changes
const cRole = localStorage.getItem("c_role");
const additionalRoutes = cRole === "startup" ? startupRoutes : investorRoutes;
export const router = createBrowserRouter([
    // default routes
    {
        path: "/",
        element: await userIsLoggedIn() ? <Navigate to="/verify-auth" /> : <HomePage />,
        errorElement: <ErrorPage />
    },
    {
        path: "/access-request",
        element: await userIsLoggedIn() ? <Navigate to="/verify-auth" /> : <RequestAccess />,
        errorElement: <ErrorPage />
    },
    // {
    //     path: "/company-registration",
    //     element: await userIsLoggedIn() ? <Navigate to="/verify-auth" /> : <CompanyRegistration />,
    //     errorElement: <ErrorPage />
    // },
    {
        path: "/login",
        element: await userIsLoggedIn() ? <Navigate to="/verify-auth" /> : <LoginPage />,
        errorElement: <ErrorPage />
    },
    {
        path: "/request-password-reset",
        element: await userIsLoggedIn() ? <Navigate to="/verify-auth" /> : <ResetPassword />,
        errorElement: <ErrorPage />
    },
    {
        path: "/accept-invite",
        element: await userIsLoggedIn() ? <Navigate to="/verify-auth" /> : <RegisterInvitedUser />,
        errorElement: <ErrorPage />
    },
    {
        path: "/set-password",
        element: await userIsLoggedIn() ? <Navigate to="/verify-auth" /> : <SetPassword />,
        errorElement: <ErrorPage />
    },
    {
        path: "/account-pending-approval",
        element: await userIsLoggedIn() ? <PendingApproval /> : <Navigate to="/login" />,
        errorElement: <ErrorPage />
    },
    {
        path: "/verify-auth",
        element: <VerifyAuth />,
        errorElement: <ErrorPage />
    },
    {
        path: "/SIF-verify",
        element: <FlapIdVerify />,
        errorElement: <ErrorPage />
    },
    {
        path: "/public-file-view/:id",
        element: <PublicFileView />,
        errorElement: <ErrorPage />
    },
    ...additionalRoutes
]);