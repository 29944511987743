import { Avatar, Button, Modal, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CommentOutlined, CalendarOutlined } from "@ant-design/icons"
import SetMeetingModal from "../../common/SetMeetingModal";
import { setDocumentTitle } from "../../../helpers/seo";
import DealModal from "../../common/DealModal";
import { getCompanyDetails, getCompanyMembers, getOpenCompanyRound, getPublicCompanyDocs } from "../../../helpers/apis";
import SocialMediaLinks from "../../common/SocialMediaLinks";
import UserCard from "../../common/UserCard";
import TableWithPagination from "../../common/TableWithPagination";
import RoundAlert from "./components/RoundAlert";
import FileViewModal from "../../common/FileViewModal";


export default function StartupProfile() {
    setDocumentTitle("Company Profile - Company Name")
    const searchParams = new URLSearchParams(window.location.search);
    const action = searchParams.get('action');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [docs, setDocs] = useState([]);
    const [members, setMembers] = useState([])
    const [activeRound, setActiveRound] = useState();
    const [selectedDoc, setSelectedDoc] = useState();
    const { id } = useParams();
    const [company, setCompany] = useState({});

    const handleGetCompanyMembers = async () => {
        try {
            const companyMembers = await getCompanyMembers(id);
            setMembers(companyMembers)
        } catch (error) {
            // console.log(error)
        }
    }

    const handleOpenFile = (file) => {
        setSelectedDoc(file);
        // console.log(file)
        navigate("?action=open-doc")
    }

    const handleGetCompanyDetails = async () => {
        try {
            const company = await getCompanyDetails(id);
            // console.log("startup", company)
            setCompany(company)
        } catch (error) {
            // console.log(error)
        }

    }

    const documentsColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            render: (link, record) => <Button onClick={() => { handleOpenFile(record) }} type="default">Open</Button>
        }
    ]


    const handleGetActiveRound = async () => {
        try {
            const round = await getOpenCompanyRound(id);
            setActiveRound(round)
        } catch (error) {
            console.error(error)
        }
    }


    const handleGetCompanyDocs = async () => {
        try {
            const docs = await getPublicCompanyDocs(id);
            setDocs(docs)
            setLoading(false)
        } catch (error) {
            // console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        handleGetCompanyDetails();
        handleGetCompanyMembers();
        handleGetCompanyDocs();
        handleGetActiveRound();
    }, [])

    return loading ? <div className="w-full flex-1 flex items-center justify-center"><Spin /></div> : (
        <div className="w-full flex-1 overflow-y-auto">
            {
                action === "set-meeting" ? (
                    <Modal
                        open={true}
                        onCancel={() => navigate("/investor/startups/" + id)}
                        footer={null}
                        width={600}
                    >
                        <SetMeetingModal target={company} close={() => navigate("/investor/startups/" + id)} />
                    </Modal>
                ) : ""
            }
            {
                action === "propose-deal" ? (
                    <Modal
                        open={true}
                        onCancel={() => navigate("/investor/startups/" + id)}
                        footer={null}
                    >
                        <DealModal />
                    </Modal>
                ) : ""
            }
            {
                action === "open-doc" ? (
                    <Modal
                        open={true}
                        onCancel={() => navigate(`/${localStorage.getItem("c_role")}/company`)}
                        footer={null}

                        width={1300}
                    >
                        <FileViewModal doc={selectedDoc} />
                    </Modal>
                ) : ""
            }
            <div className="min-h-[200px] py-5 w-full" style={{ backgroundImage: "url('/dunes.jpg')", backgroundSize: "cover", backgroundAttachment: "fixed" }}>
            </div>
            <div className="p-[32px] flex flex-wrap">
                <div className="flex flex-1 flex-col">
                    <Avatar size={120} src={<img src={company.logo} alt={company.name?.charAt(0)} />} className={`mt-[-90px] ${company.logo === null ? "bg-[#007DFC] text-[24px] text-[#fff]" : " bg-[#fff] text-fast-slate"} shadow`} style={{ border: "solid 8px #fff" }}>{company.name?.charAt(0)}</Avatar>
                    <div className="">
                        <div className="text-[16px] font-bold my-5">{company.name}</div>
                        <p className="mt-5">{company.description}</p>
                        {activeRound ? <RoundAlert round={activeRound} /> : ""}
                        <div className="flex mt-5">
                            <Button type="primary" onClick={() => navigate("?action=set-meeting")} ghost icon={<CalendarOutlined />}>Set Meeting</Button>
                            {/* coid = company id */}
                            <Button type="primary" className="ml-5" onClick={() => navigate(`/investor/messages?action=new-conversation&coid=${company.unique_ID}`)} ghost icon={<CommentOutlined />}>Send Message</Button>
                        </div>
                    </div>
                    <div className="w-full flex my-5 opacity-60 items-center">
                        <SocialMediaLinks {...company} />
                    </div>
                    <div className="text-[16px] font-bold mt-[30px]">Company Documents</div>
                    <div className="mt-4">
                        <TableWithPagination columns={documentsColumns} data={docs} />
                    </div>
                </div>
                <div className="max-w-[300px] flex-1 ml-5">
                    <div className="text-[16px] font-bold font-sans">Company Members</div>
                    {
                        members.map((user, i) => <UserCard key={i} user={user} />)
                    }
                </div>
            </div>

        </div>
    );
}
