import { Button, Empty, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserAddOutlined } from "@ant-design/icons"
import UserCard from "./UserCard";
import { useNavigate } from "react-router-dom";
import AddTeammateForm from "./AddTeammateForm";
import { getCompanyMembers } from "../../helpers/apis";
import { AccountsContext } from "../../App";

export default function MyCompanyProfileSidebar() {
    const searchParams = new URLSearchParams(window.location.search);
    const action = searchParams.get('action');
    const navigate = useNavigate()
    const [team, setTeam] = useState([]);
    const { company } = useContext(AccountsContext)

    useEffect(() => {
        async function handleGetTeam() {
            const people = await getCompanyMembers(company.unique_ID);
            setTeam(people);
        }
        handleGetTeam()
    }, [])


    return (
        <div className="max-w-[300px]  h-[475px] ml-[24px] flex-1 h-full p-4 rounded-md">
            {/* <div className="text-[16px] font-bold">
                Team
            </div>
            {
                action === "add-teammate" ? (
                    <Modal
                        open={true}
                        onCancel={() => navigate(`/${localStorage.getItem("c_role")}/company`)}
                        footer={null}
                    >
                        <AddTeammateForm close={() => navigate(`/${localStorage.getItem("c_role")}/company`)} />
                    </Modal>
                ) : ""
            }
            <div className="flex mt-5">
                <Button ghost type="primary" className="flex-1" size="large" onClick={() => navigate(`?action=add-teammate`)} icon={<UserAddOutlined />}>Add a new team member</Button>
            </div>
            {
                team.length > 0 ? team.map((user, i) => (
                    <UserCard key={i} user={user} />
                )) : <Empty className="mt-5" description={"You have no teammates"} />
            } */}
        </div>
    );
}
