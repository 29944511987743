import React, { useState } from "react";
import { setDocumentTitle } from "../../../../helpers/seo";
import { Alert, Button, Form, Input, message } from "antd";
import { axiosHeaders } from "../../../../helpers/apis";
import axios from "axios";

export default function ResetPasswordForm() {
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false)
    setDocumentTitle("Reset your password - FAST Portal")

    async function onFinish(values) {
        setLoading(true)
        console.log(values)
        try {
            await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/auth/request-password-reset/${values.email}?frontend=${window.location.origin}/set-password`, axiosHeaders);
            setSent(true)
        } catch (error) {
            setLoading(false);
            message.error(error.response.data.detail)
        }
    }

    return (
        <div className="w-full flex flex-col justify-center text-start h-full p-[48px]">
            <div className="text-fast-green font-sans font-[900] text-lg mb-5">Request password reset</div>
            <p className="mb-5">We will send a link to your email. You can use this link to enter your email.</p>
            {
                sent ? (
                    <Alert type="success" description="An email with the link to reset your password has been sent to your email. Follow the link and complete the steps to reset your password" showIcon />
                ) : (
                    <Form
                        name="reset-form"
                        layout="vertical"
                        onFinish={onFinish}
                    >
                        <Form.Item
                            label="Email"
                            name="email"
                            className="mb-5"
                            rules={[
                                { required: true, message: "Please enter your email!" },
                                { type: "email", message: "Please enter a valid email address!" },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" loading={loading} className="hover:bg-fast-green mb-3 text-white-600" htmlType="submit" block>
                                Request
                            </Button>
                        </Form.Item>
                        <p className="muted"><a href="/login"><u>Log In</u></a></p>
                    </Form>
                )
            }

        </div>
    );
}
