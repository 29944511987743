import React from "react";
import TopStartupsCard from "./TopStartupsCard";
import { Empty } from "antd";

export default function TopStartups() {
    return (
        <div className="max-w-[320px] rounded-sm flex flex-col items-center sm-none h-full flex-1 company-dashboard-sidebar ml-[24px]">
            <div className="font-bold text-[16px]">Top Startups</div>
            <div className="mt-[24px] w-full">
                <Empty />
            </div>
        </div>
    );
}
