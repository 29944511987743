import React, { useContext, useEffect, useState } from "react";
import { getCompanyDetails, getCompanyMembers, startConversation } from "../../helpers/apis";
import { Alert, Button, Form, Input, Select, message } from "antd";
import { AccountsContext } from "../../App";
import AddParticipantsField from "./AddParticipantField";
import { useNavigate } from "react-router-dom";

export default function NewConversationModal() {
    const navigate = useNavigate();
    const { company, user } = useContext(AccountsContext);
    const [targetCompany, setTargetCompany] = useState();
    const searchParams = new URLSearchParams(window.location.search);
    const companyId = searchParams.get('coid');
    const [participants, setParticipants] = useState([]);
    const [loading, setLoading] = useState(false);



    const handleGetAllMembers = async () => {
        try {
            const targetMembers = await getCompanyMembers(companyId)
            const companyMembers = await getCompanyMembers(company.unique_ID)
            setParticipants([...targetMembers, ...companyMembers])
        } catch (error) {
            // console.log("Error", error)
        }
    }


    const handleGetTargetCompany = async () => {
        try {
            const company = await getCompanyDetails(companyId);
            setTargetCompany(company)
            handleGetAllMembers();

        } catch (error) {
            // console.log("Error", error)
            message.error(error.response.data.detail)
        }
    }


    const onFinish = async (values) => {
        setLoading(true);
        values.user_id = user.id;
        // console.log('Form values:', values);
        try {
            // create conversation
            await startConversation(values);
            navigate(`/${localStorage.getItem("c_role")}/messages`)
        } catch (error) {
            message.error("Failed to start conversation")
            setLoading(false)
        }

    };

    useEffect(() => {

        handleGetTargetCompany();
    }, [])


    return (
        <div>
            <div className="w-full">
                <div className="text-[16px] font-bold mb-5">Start a new conversation with members of {targetCompany?.name}</div>
                <Alert message="Please avoid spamming to keep your company account from getting suspended" className="mb-5" type="warning" showIcon />
                <div className="mt-5">
                    <Form onFinish={onFinish} layout="vertical">
                        <Form.Item
                            label="Conversation title"
                            name="title"
                            rules={[
                                { required: true, message: 'Please enter conversation title' },
                            ]}
                        >
                            <Input placeholder="Enter conversation title" />
                        </Form.Item>

                        <AddParticipantsField Form={Form} userOptions={participants} />

                        <Form.Item
                            label="Message"
                            name="initial_msg"
                            rules={[
                                { required: true, message: 'Please enter a message' },
                            ]}
                        >
                            <Input.TextArea placeholder="Enter your message" rows={4} />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                Create Conversation
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
}
