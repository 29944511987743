import { Avatar } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useRef } from "react";
import Linkify from 'react-linkify';
import { AccountsContext } from "../../App";
import { markMessagesAsRead } from "../../helpers/apis";

export default function MessagesWindow({ messages }) {
    const { user, getNumberOfUnreadConversations } = useContext(AccountsContext);
    const messagesRef = useRef(null);


    const handleMarkMessagesAsRead = async () => {
        const ids = []
        
        messages.forEach((message) => {
            const readerIds = message.readers.map((reader) => reader.id);
            if (readerIds.indexOf(user.id) === -1) {
                ids.push(message.id);
            }
        })
        //    call request
        markMessagesAsRead(ids, user.id);
    }

    // Function to scroll to the bottom of the chat messages
    const scrollToBottom = () => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    };

    // Scroll to the bottom whenever the component updates or new messages are added
    useEffect(() => {
        scrollToBottom();
        handleMarkMessagesAsRead();
        getNumberOfUnreadConversations();
    }, [messages]);

    const myId = user.id


    return (
        <div className="flex-1 max-h-[500px] overflow-y-auto py-5" ref={messagesRef}>
            {messages?.map((message, i) => {
                const sent = message.sender.id === myId;
                return (
                    <div key={i} className="message-box">
                        <div className=" w-[400px] flex">
                            <Avatar size={40} className="mr-3 min-w-[40px]" src={message.sender.profile_pic}>{message.sender.username.split(" ")[0].charAt(0)}</Avatar>
                            <div className="flex flex-1 flex-col">
                                <div className="flex mb-3 font-bold">
                                    <div>
                                        <p>{message.sender.username}</p>
                                    </div>
                                    <img src={message.sender.company_details.logo} className="ml-3" width={24} height={24} />
                                </div>
                                <div className={`rounded-md max-w-[300px] break-words w-full content ${sent ? "sent" : "received"}`}>
                                    <Linkify>
                                        {message.content}
                                    </Linkify>
                                </div>
                                <small className="mt-3 opacity-70">{moment(message.created_at).fromNow()}</small>
                            </div>
                        </div>
                    </div>

                )
            })}
        </div>
    );
}
