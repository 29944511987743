import { Alert, Avatar } from "antd";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AccountsContext } from "../../App";

export default function MeetingCard({ meeting }) {
    const { company } = useContext(AccountsContext);
    const navigate = useNavigate();
    const start = moment(meeting.start_time);
    const end = moment(meeting.end_time)
    return (
        <div className="cursor-pointer mr-4 hover:shadow-md border rounded-md p-5 min-w-[300px] flex flex-col items-center" onClick={() => navigate(`?action=open-meeting&open-meeting-id=${meeting.id}`)}>
            <div className="w-full text-right">
                {start.format("DD/MM/YY HH:mm")} ({moment.duration(end.diff(start)).asMinutes()} minutes)
            </div>
            <Avatar src={meeting.scheduler_details.profile_pic} className="uppercase mt-5" size={48}>{meeting.scheduler_details.username.split(" ")[0].charAt(0)} {meeting.scheduler_details.username.split(" ")[1]?.charAt(0)}</Avatar>
            <div className="font-bold my-5 text-[16px] font-sans">
                {meeting.scheduler_details.company_details.name}
            </div>
            <p className="text-center">{meeting.title}</p>
            <Alert showIcon type="info" className="mt-5" description={
                meeting.scheduler_details.company_details?.unique_ID === company.unique_ID ? `${company.name} organized this meeting` : `${company.name} was invited to this meeting`} />
        </div>
    );
}
