import axios from "axios"
import { message } from "antd"
import { getOtherCompanyDetails } from "./messaging"

export const axiosHeaders = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem("fsttkn")}`
    }
}

export const getCompanyDetails = async (companyId) => {
    let companyDetails = {}
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/${companyId}/details`, axiosHeaders);
        companyDetails = request.data.company_details
    } catch (error) {
        console.error(error)
    }
    return companyDetails;
}


export const getUserDetails = async () => {
    let user = {};
    await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/users/details/${localStorage.getItem("uid")}`, axiosHeaders)
        .then((res) => {
            user = res.data.user_details
        })
        .catch(err => {
            console.error(err); // Log any errors that occur during the API request
        });
    return user;
}

export const getUserDetailsByID = async (id) => {
    let user = {};
    await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/users/details/${id}`, axiosHeaders)
        .then((res) => {
            user = res.data.user_details
        })
        .catch(err => {
            console.error(err); // Log any errors that occur during the API request
        });
    return user;
}

export const getInvestorNetworks = async (companyId) => {
    let networks = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/network/startups/${companyId}`, axiosHeaders);
        // console.log(request.data);
    } catch (error) {
        console.error(error)
    }

    return networks;
}

export const getStartupNetworks = async (companyId) => {
    let networks = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/network/investors/${companyId}`, axiosHeaders);
        networks = request.data.companies;
    } catch (error) {
        // console.error(error)
    }

    return networks;
}

export const getCompanyRounds = async (companyId) => {
    let rounds = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/rounds/company/${companyId}`, axiosHeaders);
        rounds = request.data.rounds;
    } catch (error) {
        console.error(error)
    }

    return rounds;
}


export const getInvestors = async () => {
    let investors = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/type/investor`, axiosHeaders);
        investors = request.data.companies
    } catch (error) {
        console.error(error)
    }

    return investors;
}

export const getStartups = async () => {
    let startups = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/type/startup`, axiosHeaders);
        startups = request.data.companies
    } catch (error) {
        console.error(error)
    }

    return startups;
}

export const getCompanyDocs = async (companyId) => {
    let docs = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/docs/${companyId}`, axiosHeaders);
        docs = request.data.docs
    } catch (error) {
        console.error(error)
        message.error(error.response.data.detail)
    }

    return docs;
}


export const getPublicCompanyDocs = async (companyId) => {
    let docs = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/docs/${companyId}?visibility=public`, axiosHeaders);
        docs = request.data.docs
    } catch (error) {
        console.error(error)
        message.error(error.response.data.detail)
    }

    return docs;
}

export const addCompanyDocument = async (data) => {
    const formData = new FormData();

    // Append each field to the formData instance
    for (const key in data) {
        formData.append(key, data[key]);
    }

    return await axios.post(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/docs/`, formData, axiosHeaders);
}

export const updateCompanyDocument = async (data, id) => {
    const formData = new FormData();

    // Append each field to the formData instance
    for (const key in data) {
        formData.append(key, data[key]);
    }

    return await axios.put(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/docs/${id}`, formData, axiosHeaders);
}

export const deleteCompanyDocument = async (id) => {
    return await axios.delete(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/docs/${id}`, axiosHeaders);
}


export const logCompanyDocAccess = async (docUniqueID, userEmail) =>{
    /**Log CompanyDoc access
     * 
     */

    const accessLog = {
        "doc_unique_ID": docUniqueID,
        "user_email": userEmail
    }
    const accessLoggingURL = `${import.meta.env.VITE_API_ENDPOINT_URL}/company/docs/access`

    try {
        const request  = await axios.post(accessLoggingURL, accessLog, axiosHeaders)
        const resp_message = request.data.message
        // console.log(resp_message)

    } catch (error) {
        // console.error(error)
    }

}


export const getCompanyDocAccessLogs = async (doc_unique_ID) => {
    /** Get Access Logs on company doc
     * 
     * doc_access = {'id': 1, 'doc_unique_ID': '393828f7-fc77-41ff-af51-36cf534ee7a7', 'user_email': 'email@abc.com', 'created_at': '2023-08-28T09:23:29.058357+00:00'},
     */
    let docAccessLogs = [];

    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/docs/access/${doc_unique_ID}`, axiosHeaders);
        docAccessLogs = request.data.doc_accesses
    } catch (error) {
        console.error(error)
        message.error(error.response.data.detail)
    }

    return docAccessLogs;
}


export const updateCompanyProfile = async (data, companyID) => {
    const formData = new FormData();

    // Append each field to the formData instance
    for (const key in data) {
        formData.append(key, data[key]);
    }

    return await axios.put(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/${companyID}/details`, formData, axiosHeaders);
}


export const addconversationsMember = async (data) => {
    return await axios.post(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/invite-team_member/`, data, axiosHeaders);
}


export const getCompanyMembers = async (companyId) => {
    let team = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/${companyId}/members`, axiosHeaders);
        team = request.data.members;
    } catch (error) {
        console.error(error)
    }

    return team;
}

export const proposeDeal = async (roundID, deal) => {
    return await axios.post(`${import.meta.env.VITE_API_ENDPOINT_URL}/deal/${roundID}`, deal, axiosHeaders);
}

export const getInvestorDeals = async (companyId) => {
    let deals = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/deal/investor/${companyId}`, axiosHeaders);
        deals = request.data.deals;
    } catch (error) {
        console.error(error)
    }

    return deals;
}

export const getStartupDeals = async (companyId) => {
    let deals = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/deal/startup/${companyId}`, axiosHeaders);
        deals = request.data.deals;
    } catch (error) {
        console.error(error)
    }

    return deals;
}

export const getConversations = async (userId, companyId) => {
    let conversations = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/message/my-conversations/${userId}`, axiosHeaders);
        conversations = request.data.conversations.map((conversation) => ({ ...conversation, other_company: getOtherCompanyDetails(companyId, conversation)[0] })).reverse()
    } catch (error) {
        console.error(error)
    }

    return conversations;
}

export const getOpenCompanyRound = async (companyId) => {
    let rounds = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/rounds/company/${companyId}`, axiosHeaders);
        rounds = request.data.rounds.reverse()[0];
    } catch (error) {
        console.error(error)
    }

    return rounds;
}

export const addTeamMember = async (data) => {
    const frontendUrl = `${window.location.origin}/accept-invite`;
    return await axios.post(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/invite-team_member/?frontend=${frontendUrl}`, data, axiosHeaders);
}

export const getDoc = async (id) => {
    let doc;
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/docs/one/${id}`, axiosHeaders);
        doc = request.data.doc_details
    } catch (error) {
        message.error(error.response.data.detail)
    }

    return doc;
}

export const startConversation = async (data) => {
    return await axios.post(`${import.meta.env.VITE_API_ENDPOINT_URL}/message/start-conversation`, data, axiosHeaders);
}

export const sendMessage = async (data, conversationId) => {
    return await axios.post(`${import.meta.env.VITE_API_ENDPOINT_URL}/message/send-message/${conversationId}`, data, axiosHeaders);
}

export const getNotifications = async (userId) => {
    let notifications = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/notifications/user/${userId}`, axiosHeaders);
        notifications = request.data.notifications;
    } catch (error) {
        console.error(error)
    }

    return notifications;
}

export const scheduleMeeting = async (data) => {
    return await axios.post(`${import.meta.env.VITE_API_ENDPOINT_URL}/meetings/schedule`, data, axiosHeaders);
}

export const getMeetings = async (userId) => {
    let meetings = [];
    try {
        const request = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/meetings/user/${userId}`, axiosHeaders);
        meetings = request.data.meetings;
    } catch (error) {
        console.error(error)
    }

    return meetings;
}

export const markNotificationsAsRead = async (notificationIds) => {
    const notifications = []

    // attach objects
    notificationIds.forEach((id) => {
        notifications.push({
            "id": id,
            "status": "seen"
        })
    })

    // make request
    return await axios.put(`${import.meta.env.VITE_API_ENDPOINT_URL}/notifications/update-status`,
        { notifications }
        , axiosHeaders);
}

export const markMessagesAsRead = async (ids, userId) => {
    return await axios.patch(`${import.meta.env.VITE_API_ENDPOINT_URL}/message/read-message/user/${userId}`,
        { ids }
        , axiosHeaders);
}

export const retractDeal = async (dealId) => {

    const data = new FormData();
    data.append("status", "retracted")
    return await axios.put(`${import.meta.env.VITE_API_ENDPOINT_URL}/deal/review/${dealId}?status=retracted`, null, axiosHeaders);
}

export const rejectDeal = async (dealId) => {
    return await axios.put(`${import.meta.env.VITE_API_ENDPOINT_URL}/deal/status/${dealId}?status=rejected`, null, axiosHeaders);
}

export const acceptDeal = async (dealId, signedTermSheet) => {
    const data = new FormData();
    data.append("status", "accepted")
    data.append("signed_term_sheet", signedTermSheet)
    return await axios.put(`${import.meta.env.VITE_API_ENDPOINT_URL}/deal/status/${dealId}`, data, axiosHeaders);
}

export const sendAccessRequest = async (data) => {
 return await axios.post(`${import.meta.env.VITE_API_ENDPOINT_URL}/access_requests/create`,data,axiosHeaders)
}

export const registerInvitedUser = async (data,token) => {
    return await axios.post(`${import.meta.env.VITE_API_ENDPOINT_URL}/company/accept-invite/?token=${token}&frontend_login_url=${window.location.origin}`, data, axiosHeaders);
}