import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import OpenRoundCta from "./OpenRoundCta";
import Modal from "antd/es/modal/Modal";
import RoundForm from "../common/RoundForm";
import { AccountsContext } from "../../App";
import StartInvestingCTA from "./StartInvestingCTA";

export default function Sidebar({ menuItems }) {
    const { company } = useContext(AccountsContext)
    const [roundModalOpen, setRoundModalOpen] = useState(false);


    function getRounds() {
        // console.log("Getting rounds")
    }

    return (
        <>
            <div className=" mt-2 items-conter flex  rounded-sm text-center sm:text-[15px] md:text-[20px] lg:text-[20px] font-sans font-[900]" onClick={() => window.location.reload()}>
                <img src="/FAST.svg" className="mr-3" width={20} alt="FAST Portal icon" />
                FAST Portal 
                {/* FAST Portal <i className="font-light italic ml-1 mt-3 text-[14px] text-fast-green">beta</i> */}
            </div>
            <div className="menu-items flex-1 sm:min-h-[350px] sm:mt-[40px] lg:mt-[28px]">
                {menuItems.map((menuItem, i) => (
                    <NavLink key={menuItem.text} target={menuItem.target} className={`p-[16px] mt-[4px] relative rounded-sm flex items-center ${menuItem.route === "/disabled" ? " disabled" : null}`} to={menuItem.route === "/disabled" ? window.location.pathname : menuItem.route}>
                        <div className="mr-5">{menuItem.icon}</div>
                        <span>{menuItem.text}</span>
                    </NavLink>
                ))}
            </div>
            {/* round modal */}
            {roundModalOpen ? <Modal
                open={true}
                onCancel={() => setRoundModalOpen(!roundModalOpen)}
                footer={null}
            ><RoundForm getRounds={getRounds}  onCancel={() => setRoundModalOpen(!roundModalOpen)}/></Modal> : ""}

            {company.type === "startup" ? <OpenRoundCta openModal={setRoundModalOpen} /> : <StartInvestingCTA />}
        </>
    );
}
