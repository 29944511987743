import { useState } from "react";
import { setDocumentTitle } from "../../../../helpers/seo";
import { Button, Form, Input, message } from "antd";
import { axiosHeaders } from "../../../../helpers/apis";
import axios from "axios";

export default function SetPasswordForm() {
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false)
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('token');


    setDocumentTitle("Set your password - FAST Portal");

    const onFinish = async (values) => {
        setLoading(true);
        values.confirm_password = values.password;
        const formData = new FormData();
        for (const key in values) {
            formData.append(key, values[key]);
        }
        try {
            await axios.post(`${import.meta.env.VITE_API_ENDPOINT_URL}/auth/set-password?token=${token}&frontend=${window.location.origin}`, formData, axiosHeaders)
            setSent(true);
            message.success("Your password has been reset");
        } catch (error) {
            message.error("Failed to reset password");
            setLoading(false)
        }
    }

    return (
        <div className="w-full flex flex-col justify-center text-start h-full p-[48px]">
            <div className="text-fast-green font-sans font-[900] text-lg mb-5">Set your password</div>
            <p className="mb-5">Please enter your new password.</p>
            {sent ? (
                <>
                    Password successfully reset. You can now <a href="/login"><u>login using your new password.</u></a>
                </>
            ) : (
                <Form
                    name="reset-form"
                    layout="vertical"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label="Password"
                        name="password"
                        className="mb-5"
                        rules={[
                            { required: true, message: "Please enter your password!" },
                            { type: "password", message: "Please enter a valid password!" },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" loading={loading} className="hover:bg-fast-green mb-3 text-white-600" htmlType="submit" block>
                            Reset
                        </Button>
                    </Form.Item>
                    <p className="muted"><a href="/login"><u>Log In</u></a></p>
                </Form>
            )}



        </div>
    );
}
