import React, { useContext, useEffect, useState } from "react";
import { setDocumentTitle } from "../../../helpers/seo";
import { FileExcelOutlined, EyeOutlined } from "@ant-design/icons";
import TableWithPagination from "../../common/TableWithPagination";
import { Button, Empty, Modal, Select, Skeleton } from "antd";
import RoundForm from "../../common/RoundForm";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ManageRoundModal from "../../common/ManageRoundModal";
import DealSummaryModal from "../../common/DealSummaryModal";
import { getCompanyRounds, getStartupDeals } from "../../../helpers/apis";
import { AccountsContext } from "../../../App";
import ExcelJS from 'exceljs';
export default function StartupFunding() {
    setDocumentTitle("Funding - FAST Portal");
    const { company } = useContext(AccountsContext);
    const searchParams = new URLSearchParams(window.location.search);
    const openRoundId = searchParams.get('round-id');
    const openDealId = searchParams.get('deal-id');
    const navigate = useNavigate()
    const [roundModalOpen, setRoundModalOpen] = useState(false);
    const [deals, setDeals] = useState([]);
    const [rounds, setRounds] = useState([]);
    const [loadingRounds, setLoadingRounds] = useState(true);


    useEffect(() => {
        getRounds()
        handleGetDeals()
    }, [])


    const exportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Add headers
        const headers = Object.keys(deals[0]);
        worksheet.addRow(headers);

        // Add data rows
        deals.forEach(row => {
            const data = Object.values(row);
            worksheet.addRow(data);
        });

        // Generate a buffer containing the Excel data
        const buffer = await workbook.xlsx.writeBuffer();

        // Create a Blob from the buffer
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        // Create a download link
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = Math.floor(Math.random() * 10000000000) + '.xlsx';
        a.click();
        URL.revokeObjectURL(url);
    };


    async function handleGetDeals() {
        try {
            const deals = await getStartupDeals(company.unique_ID);
            setDeals(deals.reverse())
        } catch (error) {
            // console.log(error)
        }
    }
    async function getRounds() {
        const response = await getCompanyRounds(company.unique_ID);
        setRounds(response.reverse())
        setLoadingRounds(false)
    }

    const roundsColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'roundName'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },

        {
            title: 'Target Amount',
            dataIndex: 'target_amount',
            key: 'round_target_amount',
            render: (target_amount) => "$" + target_amount.toLocaleString()
        },
        {
            title: 'Amount Raised',
            dataIndex: 'amount_raised',
            key: 'round_amount_raised',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'roundStatus',
            render: (status) => (<span className={`${status === "open" ? "bg-[#3064E8] text-[#fff]" : "bg-[#2AB2A6] text-[#fff]"} rounded-full p-1 px-3`}>{status}</span>)
        },
        {
            title: '',
            key: 'roundAction',
            render: (record) => (<Button type="default" onClick={() => navigate(`?round-id=${record.id}`)}>Manage</Button>)
        },
    ];

    const dealColumns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'dealName'
        },
        {
            title: 'Investor',
            dataIndex: 'investor_details',
            key: 'investor',
            render: (investor_details, _record) => (investor_details.name)
        },

        {
            title: 'Amount Raised',
            dataIndex: 'amount',
            key: 'deal_amount_raised',
        },
        {
            title: 'Round',
            dataIndex: 'round_details',
            key: 'round',
            render: (round_details, record) => (round_details.name)
        },
        {
            title: 'Date Created',
            dataIndex: 'date_offered',
            key: 'deal_date_created',
            render: (date) => moment(date).format("DD/MM/YY HH:mm")
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'dealStatus',
            render: (status) => (<span className={`${status === "open" ? "bg-[#3064E8] text-[#fff]" : "bg-green-400"}  rounded-full p-1 px-3`}>{status}</span>)
        },
        {
            title: '',
            key: 'dealAction',
            render: (record) => <Button type="default" className="min-w-[30px] ml-2 " onClick={() => { navigate(`?deal-id=${record.id}`) }}><EyeOutlined /></Button>
        },
    ];

    return (
        <div className="flex overflow-y-auto flex-1 h-full px-[32px]">
            <div className="flex-1 overflow-y-auto">
                {openRoundId ? <Modal
                    open={true}
                    getRounds={getRounds}
                    onCancel={() => navigate("/startup/funding")}
                    footer={null}
                ><ManageRoundModal getRounds={getRounds} close={() => navigate("/startup/funding")} /></Modal> : ""}
                {
                    openDealId ? (
                        <Modal
                            open={true}
                            onCancel={() => navigate("/startup/funding")}
                            footer={null}
                        >
                            <DealSummaryModal deal={deals.filter((deal) => deal.id.toString() === openDealId)[0]} onCancel={() => navigate("/startup/funding")} />
                        </Modal>
                    ) : ""
                }
                {roundModalOpen ? <Modal
                    open={true}

                    onCancel={() => setRoundModalOpen(!roundModalOpen)}
                    footer={null}
                ><RoundForm getRounds={getRounds} onCancel={() => setRoundModalOpen(!roundModalOpen)} /></Modal> : ""}
                <div className="px-4 flex my-[48px] justify-between w-full ">
                    <div className=" font-bold text-[20px]">Rounds</div>
                    <div className="flex items-center">
                        <div className="font-bold">
                            Total Raised: $0
                        </div>
                        <Button type="primary" size="large" className="ml-4" onClick={() => setRoundModalOpen(!roundModalOpen)}> Start a round</Button>

                    </div>
                </div>
                <div className="mt-5">
                    {loadingRounds ? <Skeleton /> : <TableWithPagination key="round-table" data={rounds} columns={roundsColumns} />}

                </div>
                <div className="px-4 flex my-[48px] justify-between w-full font-bold text-[20px]">
                    <div>
                        Deals
                    </div>
                    <div className="flex items-center">
                        <Select value="">
                            <Select.Option value="" default>Filter by round</Select.Option>
                            {
                                rounds.map((round) => <Select.Option value={round.name}>{round.name}</Select.Option>)
                            }
                        </Select>
                        <Button type="default" onClick={() => exportToExcel()} className="ml-4" icon={<FileExcelOutlined />}>Export deals</Button>
                    </div>


                </div>
                <TableWithPagination data={deals} key="deal-table" columns={dealColumns} />
            </div>
            <div className="max-w-[320px] rounded-sm flex flex-col items-center sm-none  flex-1 company-dashboard-sidebar ml-[24px]">
                <div className="font-bold text-[16px]">Top Investors</div>
                <div className="mt-[24px] w-full">
                    <Empty />
                </div>
            </div>
        </div>
    );
}
