import React, { useContext, useState } from "react";
import { Form, Input, Select, Button, message } from 'antd';
import axios from "axios";
import { AccountsContext } from "../../App";
import { axiosHeaders } from "../../helpers/apis";
const { Option } = Select;


export default function RoundForm({ getRounds, onCancel }) {
    const [loading, setLoading] = useState(false);
    const { company } = useContext(AccountsContext);
    const onFinish = async (values) => {
        setLoading(true);
        values.startup_id = company.unique_ID;
        values.status = "open";

        // console.log('Form values:', values);
        axios.post(`${import.meta.env.VITE_API_ENDPOINT_URL}/rounds/`, values, axiosHeaders).then((res) => {
            message.success("Round successfully started !");
            getRounds();
            onCancel();
        }).catch(err => {
            setLoading(false)
            message.error(err.response.data.detail);
            onCancel();
        })
    };


    return (
        <Form
            layout="vertical"
            onFinish={onFinish}
        >
            <div className="text-[20px] font-bold font-sans">Start a round</div>
            <div className="my-5">
                <p>
                    Rounds involve raising capital from investors to support the growth, development, and operations of the startup.
                </p>
            </div>
            <Form.Item
                label="Round type"
                name="type"
                rules={[{ required: true, message: 'Please select the round type' }]}
            >
                <Select>
                    <Option value="series-a">Series A Round</Option>
                    <Option value="series-b">Series B Round</Option>
                    <Option value="series-c">Series C Round</Option>
                    <Option value="series-d">Series D Round</Option>
                    <Option value="ipo">Initial Public Offering (IPO)</Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="Target amount"
                name="target_amount"
                rules={[{ required: true, message: 'Please enter the target amount' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Round name"
                name="name"
                rules={[{ required: true, message: 'Please enter the round name' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Description"
                name="comments"
                rules={[{ required: true, message: 'Please enter the description' }]}
            >
                <Input.TextArea />
            </Form.Item>
            <div className="flex w-full mt-5 justify-end">
                <Form.Item>
                    <Button loading={loading} type="primary" className="min-w-[150px]" htmlType="submit">
                        start
                    </Button>
                </Form.Item>
            </div>

        </Form>
    );
}
