import { useEffect } from "react";
import AccessRequestForm from "./components/AccessRequestForm";
import FormsHeader from "./components/FormsHeader";
import { setDocumentTitle } from "../../../helpers/seo";

export default function RequestAccess() {
    useEffect(() => {
        setDocumentTitle("Log in to your FAST Portal account")
    }, [])

    
    return (
        <>
            <div className="flex mx-auto justify-center mb-[40px] lg:w-3/4 min-w-[320px]"> <FormsHeader /></div>
            <div className="container flex justify-center items-center text-center">

                <div className="w-full flex-wrap flex-wrap-reverse min-w-[320px] rounded-md overflow-hidden min-h-[500px] lg:w-3/4 shadow flex">
                    <div className="flex-1 min-w-[320px]"><AccessRequestForm /></div>
                    <div className="flex-1 w-full min-w-[200px] p-3" style={{ backgroundImage: "url('/dunes.jpg')", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}></div>
                </div>
            </div>
        </>
    );
}
