import { Avatar, Button, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FileCard from "../../common/FileCard";
import { CommentOutlined, CalendarOutlined, LinkedinOutlined } from "@ant-design/icons"
import SetMeetingModal from "../../common/SetMeetingModal";
import { setDocumentTitle } from "../../../helpers/seo";
import { getCompanyDetails, getCompanyMembers, getPublicCompanyDocs } from "../../../helpers/apis";
import SocialMediaLinks from "../../common/SocialMediaLinks";
import UserCard from "../../common/UserCard";
import TableWithPagination from "../../common/TableWithPagination";
import FileViewModal from "../../common/FileViewModal";

export default function InvestorProfile() {
    const [company, setCompany] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedDoc, setSelectedDoc] = useState();
    setDocumentTitle(`Company Profile - ${company.name}`)
    const searchParams = new URLSearchParams(window.location.search);
    const [docs, setDocs] = useState([]);
    const action = searchParams.get('action');
    const [members, setMembers] = useState([])
    const navigate = useNavigate();

    const { id } = useParams();

    const handleGetCompanyMembers = async () => {
        try {
            const companyMembers = await getCompanyMembers(id);
            setMembers(companyMembers)
        } catch (error) {
            // console.log(error)
        }
    }

    const handleOpenFile = (file) => {
        setSelectedDoc(file);
        // console.log(file)
        navigate("?action=open-doc")
    }

    const handleGetCompanyDetails = async () => {
        try {
            const company = await getCompanyDetails(id);
            setCompany(company)
        } catch (error) {
            setCompany(null)
            // console.log(error)
        }

    }

    const handleGetCompanyDocs = async () => {
        try {
            const docs = await getPublicCompanyDocs(id);
            setDocs(docs)
            setLoading(false)
        } catch (error) {
            // console.log(error)
            setLoading(false)
        }
    }

    useEffect(() => {
        handleGetCompanyDetails();
        handleGetCompanyMembers();
        handleGetCompanyDocs();
    }, [])

    const documentsColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Link',
            dataIndex: 'link',
            key: 'link',
            render: (link, record) => <Button onClick={() => { handleOpenFile(record) }} type="default">Open</Button>
        }
    ]

    return loading ? <div className="w-full flex-1 flex items-center justify-center"><Spin /></div> : (
        company ? (
            <div className="w-full flex-1 overflow-y-auto">
                {
                    action === "set-meeting" ? (
                        <Modal
                            open={true}
                            onCancel={() => navigate("/startup/investors/" + id)}
                            footer={null}
                            width={600}
                        >
                            <SetMeetingModal target={company} close={() => navigate("/startup/investors/" + id)} />
                        </Modal>
                    ) : ""
                }
                {
                    action === "open-doc" ? (
                        <Modal
                            open={true}
                            onCancel={() => navigate(`/${localStorage.getItem("c_role")}/company`)}
                            footer={null}

                            width={1300}
                        >
                            <FileViewModal doc={selectedDoc} />
                        </Modal>
                    ) : ""
                }
                <div className="min-h-[200px] py-5 w-full" style={{ backgroundImage: "url('/dunes.jpg')", backgroundSize: "cover", backgroundAttachment: "fixed" }}>
                </div>
                <div className="p-[32px] flex flex-wrap">
                    <div className="flex flex-1 flex-col">
                        <Avatar size={120} src={company.logo ? <img src={company.logo} alt={company.name?.charAt(0)} /> : null} className="mt-[-90px] text-fast-slate bg-[#efefef] shadow" style={{ border: "solid 8px #fff" }}>{company.name?.charAt(0)}</Avatar>
                        <div className="">
                            <div className="text-[16px] font-bold my-5">{company.name}</div>
                            <p className="mt-5">{company.description}</p>
                            <div className="flex mt-5">
                                <Button type="primary" onClick={() => navigate("?action=set-meeting")} ghost icon={<CalendarOutlined />}>Set Meeting</Button>
                                <Button type="primary" className="ml-5" ghost icon={<CommentOutlined />} onClick={() => navigate(`/startup/messages?action=new-conversation&coid=${company.unique_ID}`)}>Send Message</Button>
                            </div>
                        </div>
                        <div className="w-full flex my-5 opacity-60 items-center">
                            <SocialMediaLinks {...company} />
                        </div>
                        <div className="text-[16px] font-bold mt-[30px]">Company Documents</div>
                        <div className="mt-4">
                            <TableWithPagination columns={documentsColumns} data={docs} />
                        </div>
                    </div>
                    <div className="max-w-[300px] flex-1 ml-5">
                        <div className="text-[16px] font-bold font-sans">Company Members</div>
                        {
                            members.map((user, i) => <UserCard key={i} user={user} />)
                        }
                    </div>
                </div>

            </div>
        ) : <div className="w-full flex-1 flex items-center justify-center">Company not found.</div>
    );
}
