import React, { useContext, useEffect, useRef, useState } from "react";
import { Avatar, Button, Form, Input, Modal, message } from "antd";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { UsergroupAddOutlined, SmileOutlined, SendOutlined } from "@ant-design/icons";
import MessagesWindow from "./MessagesWindow";
import { getConversationById } from "../../helpers/messaging";
import { sendMessage } from "../../helpers/apis";
import { AccountsContext } from "../../App";
import ParticipantsModal from "./ParticipantsModal";
import { useNavigate } from "react-router-dom";

export default function ConversationBox({ conversations, id, getConversations }) {
    const searchParams = new URLSearchParams(window.location.search);
    const action = searchParams.get('action');
    const { user } = useContext(AccountsContext);
    const [conversation, setConversation] = useState({});
    const fieldRef = useRef(null)
    const [participants, setParticipants] = useState([]);
    const [messageContent, setMessageContent] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const handleEmojiSelect = (emoji) => {
        // Add the selected emoji to the message content
        setMessageContent(messageContent + emoji.native);
        form.setFieldsValue({
            message: messageContent
        });
    };

    function updateConversation() {
        setConversation(getConversationById(conversations, id));
    }

    function saveParticipants() {
        setParticipants(conversation.participants?.map((participant) => participant.username))
    }

    const handleSendMessage = async () => {
        try {
            setLoading(true)
            const data = {
                content: messageContent,
                user_id: user.id
            }

            await sendMessage(data, id);
            setMessageContent("")
            form.setFieldsValue({
                message: ""
            });
            setLoading(false);
            getConversations();
            updateConversation();
        } catch (error) {
            setLoading(false)
            message.error("Failed to send message. Please try again")
        }
    }

    useEffect(() => {
        updateConversation();
    }, [conversations])

    useEffect(() => {
        const interval = setInterval(() => {
            // console.log("updating conversations...")
            getConversations();
        }, 20000);

        return () => {
            clearInterval(interval); // This is the cleanup function
        };
    }, [])
    useEffect(() => {
        saveParticipants();
    }, [conversation])

    return (
        <div className="w-full p-5 h-full flex justify-center">
            <div className="w-full h-full flex justify-between flex-col max-w-[900px]">

                <div className="flex conversation-header justify-between">
                    <div className="flex items-center">
                        <Avatar size={60} src={conversation.other_company?.logo}>{conversation.other_company?.name.charAt(0)}</Avatar>
                        <div className="ml-3">
                            <div className="font-bold">{conversation.title} (with {conversation.other_company?.name})</div>
                            <p className="opacity-70">{participants?.toString()}</p>
                        </div>
                    </div>
                    <div className="flex items">
                        <>
                            <Button type="primary" className="px-4 max-h-[40px]" size="" ghost icon={<UsergroupAddOutlined />} onClick={() => navigate(`?cid=${id}&action=view-participants`)}>Participants</Button>
                        </>

                        <div className="ml-3"></div>
                    </div>

                </div>
                {
                    action === "view-participants" ? (
                        <Modal
                            width={500}
                            open={true}
                            onCancel={() => navigate(`/${localStorage.getItem("c_role")}/messages`)}
                            footer={null}
                        >
                            <ParticipantsModal conversation={conversation} />
                        </Modal>
                    ) : ""
                }

                <MessagesWindow messages={conversation.messages} />

                <div className="w-full">
                    <Form
                        form={form}
                        onFinish={handleSendMessage}
                        layout="inline"
                        className="flex relative justify-between items-center w-full p-2 border-t"
                    >
                        <Button type="primary" shape="circle" onClick={() => setShowEmojiPicker(!showEmojiPicker)} icon={<SmileOutlined />} style={{ width: "40px", height: "40px" }} className="mr-4 flex items-center justify-center" ghost />
                        {showEmojiPicker && (
                            <div className="absolute top-[-440px]">
                                <Picker

                                    data={data}
                                    theme="light"
                                    onEmojiSelect={handleEmojiSelect} />
                            </div>

                        )}
                        <Form.Item
                            name="message"
                            className="max-w-none"
                            style={{ flex: 1 }}
                        >
                            <Input autoComplete="off" ref={fieldRef} placeholder="Type your message here..." onChange={(e) => { setMessageContent(e.target.value) }} size="large" required />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="default"
                                htmlType="submit"
                                loading={loading}
                                className="ml-3"
                                style={{ color: "#BE5109" }}
                                icon={<SendOutlined />}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
}
