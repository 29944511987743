import React, { useContext, useState } from "react";
import HeaderSearchBar from "./HeaderSearchBar";
import { NotificationIcon } from "../../../assets/SvgComponents";
import MyAvatar from "./MyAvatar";
import ProfileCard from "./ProfileCard";
import { CommentOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom";
import { AccountsContext } from "../../../App";


export default function DesktopNav({ toggleSidebar, setNotificationsOpen }) {
    const [isProfileCardVisible, setProfileCardVisible] = useState(false);
    const { unreadMessagesCount, notifications } = useContext(AccountsContext);
    const navigate = useNavigate()

    const handleAvatarClick = () => {
        setProfileCardVisible(!isProfileCardVisible);
    };

    const unreadNotifications = notifications.filter((notification) => notification.status === "unseen").length

    return (
        <div className="w-full p-[32px]">
            <div className="flex items-center justify-between">
                <div className=""></div>
                {/* <HeaderSearchBar /> */}
                <div className="flex sm-none items-center">
                    <div className="ml-4 lg:mr-[20px] cursor-pointer" onClick={handleAvatarClick}><MyAvatar /></div>
                    {isProfileCardVisible && (
                        <div className="absolute border rounded-md top-[100px] bg-[#fff] right-[24px] w-[300px] bg-white shadow-lg p-4" style={{ zIndex: 200 }}>
                            <ProfileCard close={handleAvatarClick} />
                        </div>)}
                    {/* <div className="mx-5 relative cursor-pointer" onClick={() => navigate(`/${localStorage.getItem("c_role")}/messages`)}>
                        {unreadMessagesCount > 0 ? <div className="badge w-[18px] h-[18px] right-[-8px] top-[-8px] absolute bg-[#BE5109] text-[#fff] rounded-full flex items-center justify-center">{unreadMessagesCount}</div> : null}
                        <CommentOutlined style={{ fontSize: '20px' }} />
                    </div> */}
                    <div className="mr-3 cursor-pointer relative" onClick={() => setNotificationsOpen(true)}>
                        {unreadNotifications > 0 ? <div className="badge w-[18px] h-[18px] right-[-8px] top-[-8px] absolute bg-[#BE5109] text-[#fff] rounded-full flex items-center justify-center">{unreadNotifications}</div> : null}
                        <NotificationIcon />
                    </div>
                </div>
            </div>
            <div className="lg-none mt-5 flex items-center justify-between w-full">
                <div className="cursor-pointer" onClick={toggleSidebar}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 10H27M5 16H27M5 22H27" stroke="#2B484A" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                    </svg>
                </div>
                <div className="flex items-center">
                    <div className="ml-4 lg:mr-[20px] cursor-pointer" onClick={handleAvatarClick}><MyAvatar /></div>
                    {isProfileCardVisible && (
                        <div className="absolute border rounded-md top-[100px] bg-[#fff] right-[24px] w-[300px] bg-white shadow-lg p-4" style={{ zIndex: 200 }}>
                            <ProfileCard close={handleAvatarClick} />
                        </div>)}
                    <div onClick={() => setNotificationsOpen(true)} className="ml-4 cursor-pointer">
                        <NotificationIcon />
                    </div>
                </div>

            </div>
        </div>
    );
}
