//  menu

export const DashboardIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_70_93)">
                <path d="M9 2.12501C7.42635 2.1232 5.88506 2.57177 4.55815 3.41774C3.23125 4.26371 2.17414 5.47174 1.5117 6.89915C0.84925 8.32657 0.609129 9.91375 0.819693 11.4732C1.03026 13.0327 1.68271 14.4994 2.7 15.7L2.85 15.875H15.15L15.3 15.7C16.3173 14.4994 16.9697 13.0327 17.1803 11.4732C17.3909 9.91375 17.1507 8.32657 16.4883 6.89915C15.8258 5.47174 14.7687 4.26371 13.4418 3.41774C12.1149 2.57177 10.5736 2.1232 9 2.12501ZM13.3 6.86501L10.34 9.77001C10.4913 10.0898 10.5246 10.4529 10.4342 10.7949C10.3437 11.137 10.1352 11.4361 9.84561 11.6393C9.55598 11.8425 9.20383 11.9368 8.8514 11.9055C8.49897 11.8742 8.16894 11.7194 7.91964 11.4683C7.67034 11.2172 7.51781 10.8861 7.48902 10.5335C7.46022 10.1808 7.55701 9.82936 7.76228 9.54117C7.96754 9.25298 8.26808 9.04663 8.61076 8.95858C8.95345 8.87054 9.31623 8.90646 9.635 9.06001L12.59 6.15501L13.3 6.86501ZM1.8 9.95001H3.5V10.95H1.78C1.78 10.755 1.755 10.565 1.755 10.365C1.755 10.165 1.765 10.09 1.775 9.95001H1.8ZM4.24 4.95001L5.47 6.18001L4.735 6.87001L3.5 5.64501C3.72166 5.39212 3.96056 5.15489 4.215 4.93501L4.24 4.95001ZM9.5 4.89501H8.5V3.14501H9C9.185 3.14501 9.35 3.14501 9.5 3.17001V4.89501ZM16.245 10.37C16.245 10.565 16.245 10.765 16.22 10.955H14.46V9.95501H16.225C16.235 10.09 16.245 10.23 16.245 10.37Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_70_93">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const FundingIcon = () => {
    return (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.88173 11.349L5.94353 8.2659L8.48963 10.8297L11.3309 7.96865L9.71723 6.34375H14.2172V10.875L12.6035 9.25009L8.48963 13.3926L5.94353 10.8297L3.71693 13.0717C4.728 14.6041 6.27979 15.6921 8.05784 16.1152C9.83589 16.5384 11.7072 16.2651 13.2926 15.3507C14.878 14.4363 16.0588 12.9492 16.5959 11.1907C17.1329 9.43229 16.986 7.53402 16.1848 5.88059C15.3837 4.22717 13.9883 2.94226 12.2814 2.28624C10.5744 1.63022 8.68366 1.65216 6.9922 2.34761C5.30073 3.04307 3.93509 4.36002 3.17201 6.0316C2.40893 7.70317 2.30548 9.60435 2.88263 11.3499L2.88173 11.349ZM1.49843 12.7609L1.48943 12.7518L1.49303 12.7482C0.979967 11.5882 0.715615 10.3323 0.717231 9.0625C0.717231 4.05728 4.74653 0 9.71723 0C14.6879 0 18.7172 4.05728 18.7172 9.0625C18.7172 14.0677 14.6879 18.125 9.71723 18.125C6.05423 18.125 2.90423 15.9228 1.49843 12.7609Z" fill="currentColor" />
        </svg>

    )
}

export const HomeIcon = () => {
    return (
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.7172 14.3425V8.7945C18.7172 8.2605 18.7172 7.9935 18.6482 7.7445C18.587 7.52439 18.4864 7.31573 18.3507 7.1275C18.1966 6.9145 17.9841 6.7375 17.5559 6.3865L12.4559 2.1865C11.6633 1.5335 11.2659 1.2065 10.8197 1.0825C10.4265 0.9725 10.0079 0.9725 9.61372 1.0825C9.16747 1.2065 8.77222 1.5325 7.9796 2.1845L2.87854 6.3865C2.45141 6.7385 2.23785 6.9145 2.08485 7.1265C1.94864 7.31498 1.84766 7.52399 1.78629 7.7445C1.71722 7.9925 1.71722 8.2605 1.71722 8.7945V14.3425C1.71722 15.2745 1.71722 15.7405 1.87872 16.1075C1.9854 16.3502 2.14183 16.5708 2.33908 16.7566C2.53634 16.9424 2.77055 17.0899 3.02835 17.1905C3.41935 17.3425 3.91447 17.3425 4.90472 17.3425C5.89497 17.3425 6.3901 17.3425 6.7811 17.1905C7.0389 17.0899 7.27311 16.9424 7.47037 16.7566C7.66762 16.5708 7.82405 16.3502 7.93072 16.1075C8.09222 15.7405 8.09222 15.2745 8.09222 14.3425V13.3425C8.09222 12.8121 8.31611 12.3034 8.71462 11.9283C9.11314 11.5532 9.65364 11.3425 10.2172 11.3425C10.7808 11.3425 11.3213 11.5532 11.7198 11.9283C12.1183 12.3034 12.3422 12.8121 12.3422 13.3425V14.3425C12.3422 15.2745 12.3422 15.7405 12.5037 16.1075C12.6104 16.3502 12.7668 16.5708 12.9641 16.7566C13.1613 16.9424 13.3956 17.0899 13.6533 17.1905C14.0443 17.3425 14.5395 17.3425 15.5297 17.3425C16.52 17.3425 17.0151 17.3425 17.4061 17.1905C17.6639 17.0899 17.8981 16.9424 18.0954 16.7566C18.2926 16.5708 18.4491 16.3502 18.5557 16.1075C18.7172 15.7405 18.7172 15.2745 18.7172 14.3425Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export const NotificationIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.0002 18V18.75C15.0002 19.5456 14.6842 20.3087 14.1216 20.8713C13.5589 21.4339 12.7959 21.75 12.0002 21.75C11.2046 21.75 10.4415 21.4339 9.87891 20.8713C9.3163 20.3087 9.00023 19.5456 9.00023 18.75V18M20.0477 16.4733C18.844 15 17.9941 14.25 17.9941 10.1883C17.9941 6.46875 16.0948 5.14359 14.5315 4.5C14.3238 4.41469 14.1284 4.21875 14.0651 4.00547C13.7909 3.07219 13.0221 2.25 12.0002 2.25C10.9784 2.25 10.2091 3.07266 9.93773 4.00641C9.87445 4.22203 9.67898 4.41469 9.47133 4.5C7.90617 5.14453 6.00867 6.465 6.00867 10.1883C6.00633 14.25 5.15648 15 3.95273 16.4733C3.45398 17.0836 3.89086 18 4.7632 18H19.242C20.1096 18 20.5437 17.0808 20.0477 16.4733Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}