import { useState } from "react";
import { Form, Alert, Input, Button, message } from "antd";
import axios from "axios";
import { env } from "../../../../App";
// import { openSIF } from "../../../../helpers/auth";

export default function LoginForm() {
    const [loading, setLoading] = useState(false);
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get('error');



    // const handleOpenSIF = async () => {
    //     console.log("sif")
    //     try {
    //         const response = await openSIF();
    //         console.log(response)
    //         window.location = response.data.SIF_auth_url;
    //     } catch (error) {
    //         console.log(error)
    //         message.error("Something went wrong. Try again later")
    //     }
    // }

    const onFinish = (values) => {
        setLoading(true)
        const formData = new FormData();

        // Append each field to the formData instance
        for (const key in values) {
            formData.append(key, values[key]);
        }
        axios.post(`${env.VITE_API_ENDPOINT_URL}/auth/login`, formData).then((res) => {
            localStorage.setItem("fsttkn", res.data.accessToken.access_token);
            localStorage.setItem("uid", res.data.userDetails.id);
            window.location = "/verify-auth?action=login";
        }).catch((err) => {
            console.error(err);
            // message.error(err.response.data.detail)
            message.error("Invalid Email or Password");
            setLoading(false)
            setLoading(false)
        })
        // setTimeout(() => {
        //     localStorage.setItem("fsttkn", "token");
        //     window.location = "/verify-auth?action=login"
        // }, 3000)
    };
    return (
        <div className="w-full flex flex-col justify-center text-start h-full p-[48px]">
            {error ? <Alert className="my-3" type="error" message={error} /> : ""}
            <div className="text-fast-green font-sans font-[900] text-lg mb-5">Login</div>
            <div className="register-link my-5">
                {/* <p className="muted">Don't have an account ? <a href="/company-registration">Get your company registered !</a></p> */}
            </div>
            <Form
                name="login-form"
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    label="Email"
                    name="user_email"
                    className="mb-5"
                    rules={[
                        { required: true, message: "Please enter your email!" },
                        { type: "email", message: "Please enter a valid email address!" },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    className="fast-password-field"
                    name="user_password"
                    rules={[{ required: true, message: "Please enter your password!" }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>

                    <Button type="primary" loading={loading} className="hover:bg-fast-green mb-3 text-white-600" htmlType="submit" block>
                        Sign in
                    </Button>

                    {/* <Button type="primary" ghost block onClick={handleOpenSIF}>Sign in with Flapmax</Button> */}
                </Form.Item>
                <p className="muted"><i>Forgot password ?</i> <a href="/request-password-reset">reset.</a></p>
            </Form>
        </div>
    );
}
