import React, { useContext, useEffect, useState } from "react";
import { setDocumentTitle } from "../../helpers/seo";
import { Alert, Button, DatePicker, Form, Input, InputNumber, TimePicker, message } from "antd";
import AddParticipantsField from "./AddParticipantField";
import { getCompanyMembers, getUserDetailsByID, scheduleMeeting } from "../../helpers/apis";
import { AccountsContext } from "../../App";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";


export default function SetMeetingModal({ target, close }) {
    const { company, user } = useContext(AccountsContext)
    const [allMembers, setAllMembers] = useState([]);
    const [loading, setLoading] = useState(false);

    setDocumentTitle("Set Meeting - Company Name")
    const handleGetAllMembers = async () => {
        try {
            const a = await getCompanyMembers(company.unique_ID);
            const b = await getCompanyMembers(target.unique_ID);
            const members = [...a, ...b]
            // console.log(members)
            setAllMembers(members)
        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {
        handleGetAllMembers()
    }, [])

    const disabledDate = current => {
        // Disable dates that are before today
        return current && current < moment().endOf('day');
    };

    const handleScheduleMeeting = async (values) => {
        setLoading(true)
        try {
            const participants = await Promise.all(values.participants.map(async participant => {
                // console.log("participant", participant)
                return await getUserDetailsByID(participant);
            }));


            const startTime = moment(values.date.format("YYYY-MM-DD") + " " + values.time.format("HH:mm"));
            const endTime = moment(moment(startTime).add(values.duration, 'minutes').format("YYYY-MM-DD HH:mm"));

            const startISO = new Date(startTime).toISOString()
            const endISO = new Date(endTime).toISOString()

            const meetingData = {
                "title": values.title,
                "content": values.content,
                "start_time_utc": startISO,
                "end_time_utc": endISO,
                "organizer_user_id": user.id,
                participants
            }
            await scheduleMeeting(meetingData);
            message.success("Meeting scheduled successfully");
            close()
        } catch (error) {
            message.error("An error occurred while scheduling the meeting")
            setLoading(false)
            console.log(error)
        }
    }

    const hasSignedInWithFlapmax = localStorage.getItem("siftkn");
    return (

        <Form
            layout="vertical"
            onFinish={handleScheduleMeeting}
        >
            <div className="text-[20px] font-bold mb-5">Invite {target.name} for a meeting</div>


            <Alert className="mb-4" type="info" description="We recommend sending them a message first to inquire their availability if you haven't done so." showIcon />
            <Form.Item
                label="Meeting Title"
                name="title"
                rules={[{ required: true, message: 'Please enter the meeting title' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Date"
                name="date"
                rules={[{ required: true, message: 'Please enter the meeting title' }]}
            >
                <DatePicker className="w-full" disabledDate={disabledDate} />
            </Form.Item>

            <AddParticipantsField Form={Form} userOptions={allMembers} />

            <Form.Item
                label="Description"
                name="content"
                rules={[{ required: true, message: 'Please enter the description' }]}
            >
                <TextArea rows={4} />
            </Form.Item>
            <div className="flex">
                <Form.Item
                    label="Time"
                    name="time"
                    rules={[{ required: true, message: 'Please select a time' }]}
                >
                    <TimePicker format="HH:mm" />
                </Form.Item>
                <Form.Item
                    label="Duration (minutes)"
                    name="duration"
                    className="ml-3"
                    rules={[
                        { required: true, message: 'Please enter the duration in minutes' },
                        { type: 'number', message: 'Duration must be a number' }
                    ]}
                >
                    <InputNumber min={1} />
                </Form.Item>
            </div>
            <Form.Item className="mt-5">
                <Button htmlType="submit" type="primary" loading={loading} ghost>
                    Send Invite
                </Button>
            </Form.Item>
        </Form>
    );
}
