import React, { useEffect } from 'react';
import { Table } from 'antd';
import './TablewithPagination.css'; // Create this CSS file for custom styling

// BUG: ref key issues
const TableWithPagination = ({ data, columns }) => {
    useEffect(() => {
        // set keys to data 
        columns.forEach((el, i) => {
            el.key = i
        });
        data.forEach((el, i) => {
            el.key = i
        });
    })


    return (
        <Table
            dataSource={data}
            columns={columns}
            pagination={{
                pageSize: 10,
            }}
            className="custom-table"
            rowKey={Math.floor(Math.random() * 1000000)}
            key={Math.floor(Math.random() * 1000000)}
        />
    );
};

export default TableWithPagination;
