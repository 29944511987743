import { Button, Form, Input, Select, Upload, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useContext, useState } from "react";
import { addCompanyDocument, updateCompanyDocument } from "../../helpers/apis";
import { AccountsContext } from "../../App";

export default function FileUploadModal({ getDocs, onCancel, docs }) {
    const { user, company } = useContext(AccountsContext)
    const [file, setFile] = useState();
    const [uploading, setUploading] = useState(false)

    const onFinish = async (values) => {
        try {
            setUploading(true);
            values.uploader_id = user.id;
            values.company_ID = company.unique_ID;
            values.file = file;

            let response;
            const getFilesWithName = docs.filter(doc => doc.name === values.doc_name)
            if (getFilesWithName.length > 0) {
                values.uploader = user.id;
                response = await updateCompanyDocument( values, getFilesWithName[0].unique_ID);
            } else {
                response = await addCompanyDocument(values);
            }
            // console.log(response)
            message.success("File uploaded successfully")
            setUploading(false);
            getDocs()
            onCancel()
        } catch (error) {
            // console.log(error)
            message.error("Failed to upload file")
            onCancel()
        }

    };

    return (
        <Form
            name="antd_tailwind_form"
            layout="vertical"
            onFinish={onFinish}
        >
            <div className="text-[20px] mb-5 font-bold">Upload a file</div>
            <Form.Item
                label="File type"
                name="doc_name"
                rules={[{ required: true, message: 'Please select a file type' }]}
            >
                <Select value="" showSearch className="w-full">
                    <Select.Option value="" >Pick file type</Select.Option>
                    {
                        localStorage.getItem("c_role") === "startup" ? (
                            <>
                                <Select.Option value="Pitch-Deck" >Pitch Deck</Select.Option>
                                {/* <Select.Option value="FA2023-Bootcamp-Certificate-of-Completion" >FA2023 Bootcamp Certificate of Completion</Select.Option>
                                <Select.Option value="Certificate-of-Incorporation" >Certificate of Incorporation</Select.Option>
                                <Select.Option value="Certificate-of-Incorporation" >Certificate of Incorporation</Select.Option>
                                <Select.Option value="Articles-and-Memorandum" >Articles Of Memorandum</Select.Option>
                                <Select.Option value="Business-Plan" >Business Plan</Select.Option>
                                <Select.Option value="Financial-Projections" >Financial Projections</Select.Option>
                                <Select.Option value="Cap-Table" >Cap Table</Select.Option>
                                <Select.Option value="Operation-Licence" >Operation Licence</Select.Option>
                        <Select.Option value="Non-Disclosure-Agreement">NDA (Non-Disclosure Agreement)</Select.Option>*/}
                            </>
                        ) : (
                            <>
                                <Select.Option value="Term-Sheet">Term Sheet</Select.Option>
                                <Select.Option value="Investment-Agreement">Investment Agreement</Select.Option>
                                <Select.Option value="Due-Diligence-Checklist">Due Diligence Checklist</Select.Option>
                                <Select.Option value="Financial-Statements">Financial Statements</Select.Option>
                                <Select.Option value="Investor-Deck">Investor Deck</Select.Option>
                                <Select.Option value="Investor-References">Investor References</Select.Option>
                                <Select.Option value="Non-Disclosure-Agreement">NDA (Non-Disclosure Agreement)</Select.Option>
                            </>

                        )
                    }

                </Select>
            </Form.Item>
            <Form.Item
                label="Visibility"
                name="visibility"
                rules={[{ required: true, message: 'Please select the visibility' }]}
            >
                <Select>
                    <Select.Option value="public">Public</Select.Option>
                    <Select.Option value="private">Private</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item
                label="Upload A File"
                name="file"
                valuePropName="fileList"
                getValueFromEvent={(e) => e.fileList}
                rules={[{ required: true, message: 'Please upload a file' }]}
            >
                <Dragger onChange={({ file }) => setFile(file)} beforeUpload={() => false} accept=".pdf">
                    <p className="text-gray-700">Click or drag file to this area to upload</p>
                </Dragger>
            </Form.Item>

            <Form.Item className="mt-5">
                <Button loading={uploading} type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>

    );
}
