import {useState, useEffect} from "react";
import FullScreenLoader from "../layout/loaders/FullScreenLoader";


export default function FileViewModal({ doc }) {

    const [fileUrl, setFileUrl] = useState("");
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        if (doc.link){
            setFileUrl(doc.link);
            setLoading(false);
        }
        
    }, [])
    
    return ( 
        <div className="mt-6">
        {loading ? (<FullScreenLoader />):
            (<iframe src={fileUrl} title={doc.name} className="w-full min-h-[100vh]"></iframe>)
        }
            
        </div>
    );
}
