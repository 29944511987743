import React, { useContext, useEffect } from "react";
import FormsHeader from "./components/FormsHeader";
import { AccountsContext } from "../../../App";
import FullScreenLoader from "../../layout/loaders/FullScreenLoader";
import { setDocumentTitle } from "../../../helpers/seo";

export default function VerifyAuth() {
    const { user, company, accountsLoading } = useContext(AccountsContext);
    setDocumentTitle("authorizing...")
    useEffect(() => {
        const verifyAuth = () => {
            // check if the user and company accounts are defined and redirect if they aren't
            if (!accountsLoading) {
                // console.log(user, company)
                if (user && company) {
                    // user.AccountVerification = true;
                    return user.AccountVerification && company ? window.location = `/${company.type}/company?precursor=login` : window.location = "/account-pending-approval"
                }
                else {
                    // check if the accountsLoading process is done if it is then redirect
                    return accountsLoading ? "" : window.location = "/login?error=sorry, we could not verify your account. Please try again"
                }
            }

        }
        verifyAuth();
    }, [user, company, accountsLoading])


    return (
        <FullScreenLoader />
    );
}
