import { Alert, Button, Checkbox, Upload, message } from "antd";
import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons"
import { acceptDeal, rejectDeal } from "../../helpers/apis";

export default function DealSummaryModal({ deal, onCancel }) {
    const [confirmAccept, setConfirmAccept] = useState(false);
    const [loading,setLoading] = useState(false);
    const [signedTermsheet, setSignedTermsheet] = useState();

    const customRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("done");
        }, 1000);
    };

    const handleChange = (info) => {
        if (info.file.status === "done") {
            setSignedTermsheet(info.file.originFileObj);
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === "error") {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const handleRejectDeal = async () => {
        try {
            setLoading(true);
            await rejectDeal(deal.id);
            message.success("Deal rejected successfully");
            onCancel()
        }
        catch (error) {
            message.error("Failed to reject deal");
            setLoading(false);
        }
    }

    const handleAcceptDeal = async () => {
        try {
            if (!signedTermsheet) {
                message.error("Please upload the signed termsheet");
                return;
            }
            setLoading(true);
            await acceptDeal(deal.id,signedTermsheet);
            message.success("Deal accepted successfully");
            onCancel()
        }
        catch (error) {
            message.error("Failed to accept deal");
            setLoading(false);
        }


    }


    return (
        <div>
            <div className="my-5">
                <p className="font-bold mb-2">Deal Name :</p>
                <p className="mb-5">
                    {deal.title}
                </p>
                <p className="font-bold mb-2">Round Type :</p>
                <p className="mb-5">
                    {deal.round_details.type}
                </p>
                <p className="font-bold mb-2">Deal Amount :</p>
                <p className="mb-5">
                    ${deal.amount.toLocaleString()}
                </p>
                <div className="my-3 text-[#007DFC]"><a href={deal.document} target="_blank"><u>View term sheet</u></a></div>
                <Upload
                    customRequest={customRequest}
                    onChange={handleChange}
                    maxCount={1}
                    accept=".pdf"
                    showUploadList={{
                        showRemoveIcon: false,
                    }}
                >
                    <Button type="primary" ghost>
                        <UploadOutlined /> Upload signed termsheet
                    </Button>
                </Upload>
            </div>
            <div className="bg-[#FBF5F0] border border-[#BE5109] p-5 rounded-md">
                <p>Please review the term sheet before accepting the deal</p>
                <Checkbox className="mt-4" onChange={() => setConfirmAccept(!confirmAccept)}>I have reviewed the term sheet and accept the deal </Checkbox>
            </div>
            {deal.status === "rejected" ? (
                <Alert type="info" description="This deal was rejected" showIcon />
            ) : ""}
            {deal.status === "pending" ? (
                <div className="mt-5 flex items-center justify-between">
                    <Button type="primary" loading={loading} onClick={handleRejectDeal} ghost>Reject Deal</Button>
                    <Button type="primary" oncl disabled={!confirmAccept} onClick={handleAcceptDeal} loading={loading}>Accept</Button>
                </div>
            ) : ""}


        </div>
    );
}
