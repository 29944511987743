// Import necessary modules
import axios from "axios";
import { axiosHeaders } from "./apis";

let isLoggedInCache = null; // Cached value of logged-in status

export const userIsLoggedIn = async () => {
    if (isLoggedInCache !== null) {
        // Return cached value if available
        return isLoggedInCache;
    }

    try {
        // Make an API request to check user details
        const response = await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/users/details/${localStorage.getItem("uid")}`, axiosHeaders);

        if (response.status === 200) {
            isLoggedInCache = true;
            return true; // Token is valid, user is logged in
        } else {
            isLoggedInCache = false;
            return false; // Token is invalid or expired
        }
    } catch (err) {
        console.error(err);
        isLoggedInCache = false;
        return false;
    }
};


export async function openSIF() {
    return await axios.get(`${import.meta.env.VITE_API_ENDPOINT_URL}/auth/SIF-generate-url?redirect_url=${encodeURIComponent(`${window.location.origin}/SIF-verify`)}`);;
}