import { Avatar } from "antd";
import React from "react";
import { TagOutlined } from "@ant-design/icons"

export default function UserCard({ user }) {
    return (
        <div className="p-4 flex border rounded-sm items-center w-full bg-[#fff] mt-4">
            <Avatar size={50} src={user.profile_pic}>{user.username.split(" ")[0].charAt(0)}{user.username.split(" ")[1]?.charAt(0)}</Avatar>
            <div className="ml-3">
                <div className="font-bold">{user.username}</div>
                <p>{user.email}</p>
                <div className="flex items-center">
                    <TagOutlined />
                    <div className="ml-3"><p className="opacity-70"><i>{user.company_role}</i></p></div>
                </div>

            </div>
        </div>
    );
}
